import React, { Component, forwardRef } from "react";
import { firebase } from "../firebase-config";
import moment from "moment";

// UI
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
// Table
import MaterialTable from "material-table";
// Icons
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({
  heroHeader: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heroHeaderLoading: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  card: {
    // border: "1px solid",
    minHeight: "230px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardContent: {
    flexGrow: 1,
  },
  addCardContent: {
    minHeight: "230px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  createButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

export class Projects extends Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: firebase.auth().currentUser,
      showNewProjectModal: false,
      showCopyProjectModal: false,
      projectToCopy: {
        name: "",
        id: "",
      },
      allProjects: [],
      isUpdate: false,
      orderBy: "date",
      displayBy: firebase.auth().currentUser.admin ? "cards" : "table",
      isLoadingProjects: false,
      // Modal New Project input
      id: "",
      project_name: "",
      date: moment().format("YYYY-MM-DD"),
      displayTime: true,
      from: "09:00",
      to: "17:00",
      patientSlot: 4,
      description: "",
      location: "",
      bill_to_account: "",
      life_labs_account_name: "",
      life_labs_id: "",
    };
  }

  componentDidMount() {
    this.getAllProjects();
  }

  getTimeRange = () => {
    let quarterHours = ["00", "15", "30", "45"];
    let times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        let time = i + ":" + quarterHours[j];
        if (i < 10) {
          time = "0" + time;
        }
        times.push(
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        );
      }
    }

    return times;
  };

  getTestTypes = () => {
    let testTypeLabels = [
      {name: "Empty", value: ""}, 
      {name: "PCR", value: "pcr"}, 
      {name: "Rapid - Step-by-step", value: "rapid"},
      {name: "Rapid - Supervised", value: "rapid-supervised"},
    ];
    let testTypes = [];

    for(let i = 0; i < testTypeLabels.length; i++) {
      let testLabel = testTypeLabels[i];
      testTypes.push(
          <MenuItem key={testLabel.name} value={testLabel.value}>
            {testLabel.name}
          </MenuItem>
      );
    }
    
    return testTypes;
  };

  async getAllProjects() {
    this.setState({
      isLoadingProjects: true,
    });
    let clientId = this.props.match.params.clientid;
    let query = null;
    if(clientId) {
      let documentPath = "clients/"+ clientId + "/projects";
      console.log(documentPath);
      query = firebase.firestore().collection(documentPath)
        .orderBy('date', 'desc');
    } else {
      query = firebase.firestore().collection("projects").orderBy('date', 'desc');
    }

    if (!firebase.auth().currentUser.admin) {
      // lets show 30 days of history
      query = query
        .where("date", ">=", moment().subtract(30, 'days').format("YYYY-MM-DD"));
        //.where("archived", "==", false);
      // non-admin user (Dr) doesn't need to see rapid test
      
    }
    const snapshot = await query.get();
    snapshot.docs.map((doc) => {
      return this.setState({
        allProjects: [...this.state.allProjects, doc.data()],
      });
    });

    this.setState({
      isLoadingProjects: false,
    });
  }

  closeModal = () => {
    this.setState({
      showNewProjectModal: false,
      showCopyProjectModal: false,
    });
  };

  openNewProject = () => {
    this.setState({
      // New Project
      project_name: "",
      date: moment().format("YYYY-MM-DD"),
      from: "09:00",
      to: "17:00",
      displayTime: false,
      description: "",
      location: "",
      patientSlot: 4,
      bill_to_account: "",
      life_labs_account_name: "",
      life_labs_id: "",
      testType: "",
      // Actions
      showNewProjectModal: true,
      isUpdate: false,
    });
  };

  createProject = () => {
    let from = this.state.from;
    let to = this.state.to;

    if (!this.state.displayTime) {
      from = "00:00";
      to = "00:00";
    }

    let newProjectRef = firebase.firestore().collection("projects").doc();
    let newProjectData = {
      id: newProjectRef.id,
      date: this.state.date,
      time: { from: from, to: to },
      name: this.state.project_name,
      patientSlot: parseInt(this.state.patientSlot),
      isReminderSent: false,
      description: this.state.description,
      location: this.state.location,
      billToAccount: this.state.bill_to_account,
      lifeLabsAccountName: this.state.life_labs_account_name,
      lifeLabsId: this.state.life_labs_id,
      testType: this.state.testType,
      created_at: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      ).toUTCString(),
    };
    newProjectRef
      .set(newProjectData)
      .then(() => {
        // Add new project to allProjects
        this.setState({
          allProjects: [newProjectData, ...this.state.allProjects],
        });
        // Close Modal
        this.closeModal();
        // Add data to inicial statement
        this.clearNewProjectData();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  closeProject = () => {
    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.id);

    projectRef
      .update({
        projectClosed: true,
      })
      .then(() => {
        console.log(this.state.project_name, 'is now closed');
      })
      .then(() => {
        this.closeModal();
        this.clearNewProjectData();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  updateProject = () => {
    let from = this.state.from;
    let to = this.state.to;

    if (!this.state.displayTime) {
      from = "00:00";
      to = "00:00";
    }

    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.id);

    let projectData = {
      id: this.state.id,
      date: this.state.date,
      time: { from: from, to: to },
      description: this.state.description,
      location: this.state.location,
      name: this.state.project_name,
      patientSlot: parseInt(this.state.patientSlot),
      billToAccount: this.state.bill_to_account,
      lifeLabsAccountName: this.state.life_labs_account_name,
      lifeLabsId: this.state.life_labs_id,
      testType: this.state.testType,
    };

    projectRef
      .update(projectData)
      .then(() => {
        let tempProjects = [...this.state.allProjects];

        tempProjects.forEach((element, index) => {
          if (element.id === this.state.id) {
            projectData.patients = tempProjects[index].patients;
            tempProjects[index] = projectData;
          }
        });

        return this.setState({
          allProjects: tempProjects,
        });
      })
      .then(() => {
        // Close Modal
        this.closeModal();
        // Add data to inicial statement
        this.clearNewProjectData();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  clearNewProjectData = () => {
    this.setState({
      project_name: "",
      date: moment().format("YYYY-MM-DD"),
      from: "09:00",
      to: "17:00",
      description: "",
      location: "",
      patientSlot: 4,
      bill_to_account: "",
      life_labs_account_name: "",
      life_labs_id: "",
      testType: "",
    });
  };

  loadProject = (projectId) => {
    this.props.history.push(`/project/${projectId}`);
  };

  deleteProject = (projectId, projectName) => {
    let project = firebase
      .firestore()
      .collection("projects")
      .doc(projectId);
    project.update({
      archivedAt: moment().format("YYYY-MM-DD"),
      archived: true,
    }).then(() => {
      this.setState({
        isLoadingProjects: true,
      });
      console.log("Archiving", projectName);
      window.location.reload(false);
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
  };

  editProject = (projectId) => {
    let projectToEdit = this.state.allProjects.filter(
      (project) => project.id === projectId
    );

    this.setState({
      // Actions
      showNewProjectModal: true,
      isUpdate: true,
      // Project
      id: projectId,
      project_name: projectToEdit[0].name,
      date: projectToEdit[0].date,
      from: projectToEdit[0].time.from,
      to: projectToEdit[0].time.to,
      description: projectToEdit[0].description,
      location: projectToEdit[0].location,
      patientSlot: projectToEdit[0].patientSlot || 4,
      bill_to_account: projectToEdit[0].billToAccount,
      life_labs_account_name: projectToEdit[0].lifeLabsAccountName,
      life_labs_id: projectToEdit[0].lifeLabsId,
      displayTime: projectToEdit[0].time.from !== "00:00" ? true : false,
      // new items
      testType: projectToEdit[0].testType ?? "",
    });
  };

  openCopyProjectModal = (project) => {
    this.setState({
      showCopyProjectModal: true,
      projectToCopy: {
        name: project.name,
        id: project.id,
      },
    });
  };
  copyProject = () => {
    let docRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.projectToCopy.id);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.createNewCopy(doc.data());
        } else {
          console.log("Cant find this project");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  createNewCopy = (projectData) => {
    let newProjectRef = firebase.firestore().collection("projects").doc();

    let newProjectData = {
      id: newProjectRef.id,
      date: moment().add(1, "days").format("YYYY-MM-DD"),
      time: { from: projectData.time.from, to: projectData.time.to },
      description: projectData.description,
      patientSlot: projectData.patientSlot,
      location: projectData.location,
      name: projectData.name + " - Copy",
      patients: [],
      lifeLabsId: projectData.lifeLabsId,
      billToAccount: projectData.billToAccount,
      lifeLabsAccountName: projectData.lifeLabsAccountName,
      testType: projectData.testType,
      created_at: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      ).toUTCString(),
    };
    newProjectRef
      .set(newProjectData)
      .then(() => {
        // Add new project to allProjects
        this.setState({
          allProjects: [newProjectData, ...this.state.allProjects],
          showCopyProjectModal: false,
        });
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  copyUrl = (projectId) => {
    let url = window.location.host;
    navigator.clipboard.writeText(`${url}/project/${projectId}/form`);
  };

  sortProjects = (option) => {
    let orderBy = option.target.value;
    let newOrderProjects = [...this.state.allProjects];

    newOrderProjects.sort(function (a, b) {
      var x = a[orderBy];
      var y = b[orderBy];
      return x < y ? -1 : x > y ? 1 : 0;
    });

    if (orderBy === "date") {
      newOrderProjects.reverse();
    }

    this.setState({
      allProjects: newOrderProjects,
      orderBy: orderBy,
    });
  };

  handleRowClick = (value, rowData) => {
    this.loadProject(rowData.id);
  };

  displayProjects = (option) => {
    this.setState({
      displayBy: option.target.value,
    });
  };

  isDatePassed = (data) => {
    if (
      new Date(data.replace(/-/g, "/")).toISOString().split("T")[0] <
      new Date().toISOString().split("T")[0]
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    const {
      currentUser,
      showNewProjectModal,
      showCopyProjectModal,
      projectToCopy,
      isUpdate,
      allProjects,
      orderBy,
      displayBy,
      isLoadingProjects,
    } = this.state;

    const someProjects = this.state.allProjects.filter((project) => {
      if(project.testType !== 'rapid' && project.testType !== 'rapid-supervised' && project.archived !== true) {
        return project;
      }
      return null;
    });

    const columns = [
      {
        field: "date",
        title: "Date",
        width: 110,
        render: (rowData) =>
          new Date(rowData.date.replace(/-/g, "/")).toDateString(),
      },
      {
        field: "lifeLabsId",
        title: "PO",
        width: 110,
        hidden: currentUser.admin ? false : true,
      },

      {
        field: "lifeLabsAccountName",
        title: "Client",
        width: 110,
      },
      {
        field: "patients.length",
        title: "Volume",
        width: 110,
        render: (rowData) =>
          rowData.patients !== undefined ? rowData.patients.length : 0,
      },
      {
        field: "location",
        title: "Location",
        width: 110,
      },
      {
        field: "time.from",
        title: "Start",
        width: 110,
      },
      {
        field: "time.to",
        title: "End",
        width: 110,
      },
    ];

    return (
      <div>
        {isLoadingProjects ? (
          <Container maxWidth="lg" className={classes.heroHeaderLoading}>
            <Box display="flex">
              <CircularProgress size={100} thickness={2} />
            </Box>
          </Container>
        ) : (
          <main>
            {/* Hero unit */}
            <Container maxWidth="xl" className={classes.heroHeader}>
              <div className={classes.header}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  All Projects
                </Typography>

                <div>
                  {currentUser.admin ? (
                    <div>
                      {displayBy === "cards" ? (
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Sort by
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={orderBy}
                            onChange={this.sortProjects}
                            label="Sort by"
                          >
                            <MenuItem value={"date"}>Project Date</MenuItem>
                            <MenuItem value={"name"}>Project Name</MenuItem>
                          </Select>
                        </FormControl>
                      ) : null}

                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Display
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={displayBy}
                          onChange={this.displayProjects}
                          label="Display"
                        >
                          <MenuItem value={"cards"}>Cards</MenuItem>
                          <MenuItem value={"table"}>Table</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : null}
                </div>
              </div>
            </Container>

            {/* Dispaly cards */}
            {displayBy === "cards" ? (
              <Container className={classes.cardGrid} maxWidth="xl">
                <Grid container spacing={5}>
                  {currentUser.admin ? (
                    <Grid item key={9999999999} xs={12} sm={6} md={3}>
                      <Card variant="outlined">
                        <CardActionArea
                          onClick={() => {
                            this.openNewProject();
                          }}
                        >
                          <CardContent className={classes.addCardContent}>
                            <Box component="span">
                              <AddCircleOutlineIcon fontSize="large" />
                            </Box>
                            <Typography variant="h5" component="h2">
                              New Project
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : null}

                  {allProjects.map((card, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3} hidden={card.archived}>
                      <Card
                        variant="outlined"
                        className={classes.card}
                        style={
                          (card.testType === 'rapid' || card.testType === 'rapid-supervised') && !card.projectClosed ?
                            { borderTop: "3px solid #0080ff" }
                          : (new Date(card.date.replace(/-/g, "/"))
                            .toISOString()
                            .split("T")[0] <
                            new Date().toISOString().split("T")[0]) || card.projectClosed
                            ? { borderTop: "3px solid red" }
                            : { borderTop: "3px solid green" }
                        }
                      >
                        <CardActionArea
                          onClick={() => {
                            this.loadProject(card.id);
                          }}
                        >
                          { card.projectClosed ?
                            <Typography variant="body1" component="h2" style={{
                                backgroundColor: "red",
                                justifyContent: "center",
                                textAlign: "center",
                                color: "white",
                                paddingTop: 2,
                                paddingBottom: 2,
                            }}>
                            Project Closed
                            </Typography>
                            : null }
                          <CardContent className={classes.cardContent}>
                            <Typography variant="h5" component="h2">
                              {card.name}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                              { card.testType === 'rapid' || card.testType === 'rapid-supervised' ? "Start Date: " : "Date: "}
                              { moment(card.date).format("ddd, MMM DD YYYY") }
                            </Typography>
                            { card.time.from !== "00:00" && card.time.to !== "00:00" ?
                            <Typography color="textSecondary" variant="body2">
                              Time: {card.time.from} to {card.time.to}
                            </Typography>
                            : null }
                            <Typography color="textSecondary" variant="body2">
                              Location: {card.location}
                            </Typography>
                            <Typography variant="body2" component="p">
                              {card.description.length > 150
                                ? card.description.substring(0, 150) + "..."
                                : card.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        {currentUser.admin ? (
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              onClick={() => {
                                this.copyUrl(card.id);
                              }}
                              size="small"
                              disabled={
                                (this.isDatePassed(card.date)
                                && card.testType !== 'rapid' 
                                && card.testType !== 'rapid-supervised')
                                || card.projectClosed
                              }
                            >
                              Copy link
                            </Button>
                            <Box
                              component="span"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box component="span" mr={2}>
                                <IconButton
                                  size="small"
                                  
                                  onClick={() => {
                                    this.editProject(card.id);
                                  }}
                                >
                                  <EditRoundedIcon fontSize="small" />
                                </IconButton>
                              </Box>
                              <Box component="span" mr={2}>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    this.openCopyProjectModal(card);
                                  }}
                                >
                                  <FileCopyRoundedIcon fontSize="small" />
                                </IconButton>
                              </Box>
                              <Box component="span" mr={2}>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    this.deleteProject(card.id, card.name)
                                  }}
                                >
                                  <DeleteOutline fontSize="small" />
                                </IconButton>
                              </Box>
                              <Box component="span" mr={1}>
                                <Typography
                                  style={{
                                    display: "flex",
                                    flexalign: "center",
                                  }}
                                  color="textSecondary"
                                >
                                  <Box component="span" mr={0.5}>
                                    <GroupRoundedIcon />
                                  </Box>
                                  {card.patients !== undefined &&
                                  card.patients.length > 0
                                    ? card.patients.length
                                    : "0"}
                                </Typography>
                              </Box>
                            </Box>
                          </CardActions>
                        ) : null}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            ) : (
              // Display Table
              <Container className={classes.cardGrid} maxWidth="xl">
                <div style={{ width: "100%" }}>
                  <MaterialTable
                    title=""
                    columns={columns}
                    data={
                      this.state.currentUser.admin ? allProjects : someProjects
                    }
                    options={{
                      search: true,
                      exportButton: true,
                      sorting: true,
                      pageSize: 10,
                      pageSizeOptions: [10, 25, 50],
                      searchFieldStyle: {
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "5px",
                        borderRadius: "5px",
                      },
                      actionsColumnIndex: -1,
                      rowStyle: (rowData) => {
                        return {
                          backgroundColor: rowData.testType === "rapid" || rowData.testType === "rapid-supervised" 
                          ? "rgb(0 128 255 / 28%)" 
                          : rowData.date > moment().subtract(1, "day").format("YYYY-MM-DD")
                            ? '#ffffff' : '#ffebee',
                        };
                      },
                    }}
                    icons={tableIcons}
                    onRowClick={this.handleRowClick}
                  />
                </div>
              </Container>
            )}

            {/* Update Project - Create a New Project Modal */}
            <Dialog
              open={showNewProjectModal}
              onClose={this.closeModal}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                {isUpdate ? "Update Project" : "Create a New Project"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="project_name"
                      label="Project Name"
                      name="project_name"
                      autoFocus
                      value={this.state.project_name}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="test-type">Test Type</InputLabel>
                        <Select
                            labelId="test-type"
                            id="testType"
                            name="testType"
                            value={this.state.testType}
                            onChange={this.handleChange}
                            label="Test Type"
                            fullWidth
                          >
                            {this.getTestTypes()}
                        </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="date"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="date"
                      label={
                        this.state.testType === 'rapid' 
                          || this.state.testType === 'rapid-supervised'  
                        ? "Start Date" : "Date"
                      }
                      name="date"
                      value={this.state.date}
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  { this.state.testType === 'pcr' ?
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 12,
                        },
                      }}
                      id="patientSlot"
                      label="Patient per 15min"
                      name="patientSlot"
                      value={this.state.patientSlot}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  : null }
                  { this.state.testType !== 'rapid' ?
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.displayTime}
                            onChange={this.handleCheckChange}
                            name="displayTime"
                            color="primary"
                          />
                        }
                        label="Should project have a time?"
                      />
                    </FormGroup>
                  </Grid>
                  : null }
                  {this.state.displayTime ? (
                    <>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                        >
                          <InputLabel id="from-time">From</InputLabel>
                          <Select
                            labelId="from-time"
                            id="from"
                            name="from"
                            value={this.state.from}
                            onChange={this.handleChange}
                            label="From"
                            fullWidth
                          >
                            {this.getTimeRange()}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                        >
                          <InputLabel id="to-time">To</InputLabel>
                          <Select
                            labelId="to-time"
                            id="to"
                            name="to"
                            value={this.state.to}
                            onChange={this.handleChange}
                            label="To"
                            fullWidth
                          >
                            {this.getTimeRange()}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="location"
                      label="Address Location"
                      name="location"
                      value={this.state.location}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  { this.state.testType === 'pcr' ?
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="bill_to_account"
                      label="Life Labs Bill to Account"
                      name="bill_to_account"
                      value={this.state.bill_to_account}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  : null }
                  { this.state.testType === 'pcr' ?
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="life_labs_account_name"
                      label="Life Labs Account Name"
                      name="life_labs_account_name"
                      value={this.state.life_labs_account_name}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  : null }
                  { this.state.testType === 'pcr' ?
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="life_labs_id"
                      label="Life Labs Production ID"
                      name="life_labs_id"
                      value={this.state.life_labs_id}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  : null }
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows={4}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="description"
                      label="Details"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                      helperText="Details will be displayed on the patient booking form"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "24px" }}>
                { isUpdate && this.state.testType !== 'pcr' ?
                <Button
                  onClick={this.closeProject}
                  variant="contained"
                  color="default"
                  size="large"
                  fullWidth
                  disableElevation
                  disabled={this.state.project_name === "" ? true : false}
                >
                  Close Project
                </Button>
                : null }
                <Button
                  onClick={this.closeModal}
                  variant="outlined"
                  size="large"
                  disableElevation
                  fullWidth
                >
                  Cancel
                </Button>
                <Button
                  onClick={isUpdate ? this.updateProject : this.createProject}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disableElevation
                  disabled={this.state.project_name === "" ? true : false}
                >
                  {isUpdate ? "Update" : "Create"}
                </Button>
              </DialogActions>
            </Dialog>

            {/* Update Project - Create a New Project Modal */}
            <Dialog
              open={showCopyProjectModal}
              onClose={this.closeModal}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle
                style={{ padding: "44px 24px", textAlign: "center" }}
              >
                Are you sure you want to copy {projectToCopy.name}?
              </DialogTitle>
              <DialogActions style={{ padding: "24px" }}>
                <Button
                  onClick={this.closeModal}
                  variant="outlined"
                  size="large"
                  disableElevation
                  fullWidth
                >
                  No, Don't copy
                </Button>
                <Button
                  onClick={() => {
                    this.copyProject();
                  }}
                  variant="contained"
                  style={{ backgroundColor: "#008000", color: "#fff" }}
                  size="large"
                  fullWidth
                  disableElevation
                >
                  Yes, Copy
                </Button>
              </DialogActions>
            </Dialog>
          </main>
        )}
        ;
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Projects);
