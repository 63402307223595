import React, { Component } from "react";

// UI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

// Lifelabs bg
import lifeLabsBg from "./life-labs.svg";

const styles = (theme) => ({});

export class ToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getGenderPosition = (gender) => {
    switch (gender) {
      case "female":
        return 280;
      case "male":
        return 303;
      case "other":
        return 325;
      default:
        return 330;
    }
  };

  parseDOB = (date, type) => {
    switch (type) {
      case "year":
        return date.split(" ")[2];
      case "month":
        return date.split(" ")[0];
      case "day":
        return date.split(" ")[1];
      default:
        return date;
    }
  };

  render() {
    const { projectData, project } = this.props;
    return (
      <Container>
        {projectData.map((patient, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${lifeLabsBg})`,
              width: "100%",
              height: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "100%",
            }}
          >
            <Typography
              component="h3"
              style={{
                position: "absolute",
                marginTop: 230,
                marginLeft: 175,
              }}
            >
              <b>{project.billToAccount}</b>
            </Typography>
            <Typography
              component="h3"
              style={{
                position: "absolute",
                marginTop: 230,
                marginLeft: 580,
              }}
            >
              <b>{project.lifeLabsAccountName}</b>
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 270,
                marginLeft: 50,
              }}
            >
              {patient.last_name}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 270,
                marginLeft: 270,
              }}
            >
              {patient.first_name}
            </Typography>

            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 310,
                marginLeft: 50,
              }}
            >
              {patient.address}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 340,
                marginLeft: 175,
              }}
            >
              {patient.postal_code}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 340,
                marginLeft: 335,
              }}
            >
              {patient.phone}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 310,
                marginLeft: 535,
              }}
            >
              {this.parseDOB(patient.dob, "day")}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 310,
                marginLeft: 580,
              }}
            >
              {this.parseDOB(patient.dob, "month")}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 310,
                marginLeft: 625,
              }}
            >
              {this.parseDOB(patient.dob, "year")}
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: this.getGenderPosition(patient.gender.toLowerCase()),
                marginLeft: 682,
              }}
            >
              X
            </Typography>
            <Typography
              component="h6"
              style={{
                position: "absolute",
                marginTop: 615,
                marginLeft: 310,
              }}
            >
              <b>{project.lifeLabsId}</b>
            </Typography>
          </div>
        ))}
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ToPrint);
