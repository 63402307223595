import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
  },
});

export class DeletePatModal extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const { onClose, open, patient, deletePatient } = this.props;

    return (
      <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
          <DialogTitle style={{ padding: "44px 24px", textAlign: "center" }}>
            Are you sure you want to delete <b>{patient.first_name}</b>?
          </DialogTitle>

          <DialogActions style={{ padding: "24px" }}>
            <Button
              onClick={onClose}
              variant="outlined"
              size="large"
              disableElevation
              fullWidth
            >
              No, Don't Delete
            </Button>
            <Button
              onClick={deletePatient}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              disableElevation
              fullWidth
            >
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeletePatModal);
