import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import AuthRoute from "./utils/AuthRoute";
import { AuthProvider } from "./context/AuthContext";

// Components
import Nav from "./layout/Nav";
import Login from "./components/Login";
import Projects from "./components/Projects";
import Patients from "./components/Patients";
import Project from "./components/Project";
import NotMatch from "./components/NotMatch";
import PatForm from "./components/PatForm";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Nav />
          <Switch>
            <AuthRoute component={Login} path="/" exact />
            <AuthRoute component={Login} path="/login" exact />
            <PrivateRoute component={Projects} path="/projects" exact />
            <PrivateRoute component={Patients} path="/patients" exact />
            <PrivateRoute component={Project} path="/project/:id" exact />
            <Route component={PatForm} path="/project/:id/form" exact />
            <Route component={TermsConditions} path="/terms-conditions" exact />
            <Route component={PrivacyPolicy} path="/privacy-policy" exact />
            <Route path="*">
              <NotMatch />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
