import React, { Component } from "react";
// import { firebase } from "../firebase-config";

import { withStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const styles = (theme) => ({});

// Firebase Analytics
// const analytics = firebase.analytics();

export class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {

            }
    }

    componentDidMount() {
        console.log("Terms and conditions page loaded");
    }

    render() {
        return (
            <Container component="main" maxWidth="md">
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    style={{
                        marginTop: 25,
                        marginBottom: 25
                    }}
                    >
                SET PROTECT COVID-19 TESTING TERMS OF USE
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >
                Welcome to <a href="https://app.setprotect.ca" color="textSecondary">app.setprotect.ca</a> 
                (the “<b>Application</b>”), owned, operated and provided by Set Protect Production
                Services Inc., a company incorporated in the province of British Columbia, Canada (the “<b>Company</b>”,
                “<b>we</b>”, “<b>us</b>” or “<b>our</b>”).  BEFORE YOU CAN USE THE APPLICATION, YOU MUST READ, UNDERSTAND 
                AND AGREE TO BE BOUND BY THE FOLLOWING TERMS OF USE, ALL POLICIES AND TERMS INCORPORATED BY REFERENCE, 
                AND ANY SUBSEQUENT CHANGES TO THE FOREGOING. Your access to, and use of this Application is expressly 
                conditioned on your acceptance without modification of the following Terms of Use. By using this 
                Application, you signify your acceptance of these Terms of Use. If you do not agree with any part 
                of the following Terms of Use, you must not use this Application.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >
                We reserve the right to change or modify any of the terms and conditions contained in the Terms of Use or
                any policy or guideline of the Application, at any time and in its sole discretion. Any changes or
                modification will be effective upon posting of the revisions and notice of such changes to the Application.
                Your continued use of this Application following the posting of its changes or modifications will constitute
                your acceptance of such changes or modifications. Therefore, you should frequently review the Term of Use
                and applicable policies from time-to-time to understand the terms and conditions that apply to your use of 
                the Application.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >
                These Terms of Use do not alter in any way the terms or conditions of any other agreement you may have with
                us for other products or services. All questions or comments about the Application or its contents should 
                be directed to hello@setprotect.ca.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >1. INCORPORATED POLICIES OR TERMS</Typography>
                <Typography variant="body2" component="p">
                We believe strongly in protecting user privacy and providing you information regarding the processing of 
                personal information collected using the Application. Therefore, we have adopted a 
                <a href="https://app.setprotect.ca/privacy-policy">Privacy Policy</a> that you should refer to fully 
                understand how we use and collect information.  The terms and conditions of such Privacy Policy are 
                hereby incorporated into this Agreement and without limiting the generality of the foregoing, you 
                expressly consent and agree that we may disclose your personal information (including self-administered 
                rapid test kit results) to production companies or health authorities that engage the Company to 
                facilitate the Assessment.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >2. SET PROTECT SELF-ADMINISTERED COVID-19 TESTING</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                As part of the consideration for your acceptance of these Terms of Use, you may participate in the 
                COVID-19 self-administered test services under the Booking, Step-By-Step Guide, and Reporting sections 
                of the Application (collectively, the “Assessment”). As part of the Assessment, you may need to provide 
                to us personal information, including without limitation your name, address, phone number, email address, 
                home address, date of birth, and answers to certain questions as part of the Assessment.  In consideration 
                of your use of the Application, you agree:
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginLeft: 10
                  }}
                >a) to provide true, accurate, current and complete information about yourself and about your COVID-19 
                test results;
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginLeft: 10
                  }}
                >
                b) that the Assessment and any subsequent testing will not change the course or treatment of the illness 
                of COVID-19, or any illness;
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginLeft: 10
                  }}
                >
                c) that you represent and warrant that you have the capacity and authority to execute agreements;
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginLeft: 10
                  }}
                >
                d) that you are responsible for all costs and charges incurred in order to use the Application; and
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginLeft: 10
                  }}
                >
                e) that you are fully responsible for all activities that occur under Assessment, and accept all liability 
                for any acts or omissions arising out of your use of the Application.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                If you provide any information that is untrue, inaccurate, not current or incomplete, or we have 
                reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, 
                we may refuse any and all current and future use of the Application by you. Assessment information and 
                certain other information about you is subject to our <a href="https://app.setprotect.ca/privacy-policy">Privacy Policy</a>
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >3. NO MEDICAL OR HEALTH-RELATED ADVICE PROVIDED VIA THE APPLICATION 
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                YOU HEREBY AGREE AND ACKNOWLEDGE THAT EVERYTHING CONTAINED ON THE APPLICATION, INCLUDING WITHOUT 
                LIMITATION ITS OPERATION, CONTENTS, INFORMATION AND MATERIALS, DOES NOT CONSTITUTE MEDICAL OR 
                HEALTH-RELATED ADVICE AND SHALL NOT BE TREATED OR RELIED ON AS SUCH.  WITHOUT LIMITING THE FOREGOING, 
                THE APPLICATION IS PROVIDED TO FACILITATE THE ASSESSMENT ONLY.  IT IS NOT INTENDED AS A SUBSTITUTE FOR 
                PROFESSIONAL ADVICE, WHETHER MEDICAL, LEGAL OR OTHERWISE.  THE INFORMATION PROVIDED ON THE APPLICATION 
                HERE SHOULD NOT BE USED FOR SELF-DIAGNOSIS OR TREATING A HEALTH PROBLEM OR DISEASE.  
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >4. INTELLECTUAL PROPERTY
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                All content included on this Application is the property of the Company and/or our various third party 
                licensors, providers and distributors and is protected under the intellectual property laws of Canada 
                and other jurisdictions. None of the content found on this Application may be reproduced, republished, 
                distributed, displayed, posted, transmitted, sold, transferred, or modified in any form or by any means, 
                without the express written permission of the Company and/or its third party providers and distributors.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >The term “content” as used in this Terms of Use includes any text, graphics, images, design, trademarks 
                including logos, audio, video, software, data compilations and any other form of information capable of 
                being stored in a computer, and the selection and arrangement of the foregoing that appears on or forms 
                part of this Application.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >5. USE OF APPLICATION</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >We grant you a limited license to use this Application and to complete the Assessment. You shall not 
                use this Application for any other purposes, including without limitation, to make any speculative, 
                false or fraudulent order or any order in anticipation of demand. Such grant does not include, without 
                limitation: (a) making derivative uses of the Application or its contents; or (b) use of any data mining, 
                robots, or similar data gathering and extraction methods. Except as noted above, you are not conveyed 
                any right or license by implication, estoppel, or otherwise in or under any patent, trademark, copyright, 
                or proprietary right of the Company or any third party.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >This Application and the content provided in this Application may not be reproduced, republished, 
                distributed, displayed, posted, transmitted, sold, transferred, or modified without our written 
                permission, except that you may download, display and print the materials presented on this Application 
                for the licensed purposes only; provided that, you may not, without the permission of the Company or the 
                respective copyright owner, (a) copy, publish, or post the materials on any web site, computer network 
                or broadcast or publications media, (b) modify the materials, and (c) remove or alter any copyright, 
                trademark and other proprietary notices contained in the materials. Unauthorized use of this Application 
                and/or the materials presented on this Application may violate applicable copyright, trademark or other 
                intellectual property laws or other laws.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >You may not, without our express written permission, use, frame or utilize framing techniques to enclose 
                any trademark, logo or other proprietary information (including the images), or the content of any text 
                or the layout/design of any page or form contained on a page found at this Application. Further, you may 
                not use any meta tags or any other “hidden text” utilizing one of our names, trademarks, or product names 
                without our express written consent.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >6. AGE AND RESPONSIBILITY</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >You represent that you are of sufficient legal age, or are the legal guardian (of sufficient legal age) 
                of a person who is not of sufficient legal age, to use this Application and to create binding legal 
                obligations for any liability you may incur as a result of your use of this Application. You understand 
                that you are financially responsible for all of your uses of this Application.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >7. TERMINATION</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >You agree that we, in our sole discretion, may terminate your use of the Application, or restrict your 
                access to any component of this Application, for any reason, including without limitation, for lack of 
                use or if we believe that you have violated or acted inconsistently with the Terms of Use or applicable 
                laws or regulations. We may also, in our sole discretion and at any time, discontinue providing the 
                Application, or any part thereof, with or without notice. You agree that any termination of access to 
                the Application under any provision of the Terms of Use may be effected without notice, and acknowledge 
                and agree that we may immediately delete all related information and files relating to your Assessment, 
                if any, and/or bar any further access to such files or the Application. Further, you agree that we shall 
                not be liable to you or any third party for any termination or restriction of access to the Application.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >8. DISCLAIMER OF WARRANTIES AND REPRESENTATIONS</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >Your use of the Application and participation in the Assessment is at your sole risk. The Application, 
                Assessment and the materials therein are provided “as is” and “as available” basis. You acknowledge that 
                you have been advised by us to undertake your own due diligence with respect to this Application and the 
                Assessment. We make no representations or warranties, either express or implied, of any kind with respect 
                to the Application, the Assessment, its operation, contents, information or materials.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >We expressly disclaim all representations and warranties, express or implied, of any kind with respect 
                to this Application, the Assessment or its use, including but not limited to merchantability, fitness 
                for a particular purpose, accuracy, completeness, currency, reliability or suitability of any of the 
                content or data found on this Application, title and non-infringement, and those arising by statute or 
                otherwise in law or equity, or from a course of dealing or usage of trade.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >FURTHER, THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES OR GUARANTEES REGARDING THE USE, 
                EFFICACY OR ACCURACY OF THE COVID-19 RAPID OR PCR TESTS (THAT ARE SUPPLIED BY THIRD PARTIES) OR ANY 
                COVID-19 TEST RESULTS, AND IS MERELY FACILITATING THE ASSESSMENT PROCESS. FOR AVOIDANCE OF DOUBT, IT IS 
                YOUR RESPONSIBILITY TO UNDERSTAND THE TERMS, CONDITIONS, REPRESENTATIONS, WARRANTIES, AND/OR GUARANTEES 
                OF, AND TO COMPLY WITH ANY REQUIREMENTS FOR, ANY AND ALL THIRD PARTY COVID-19 RAPID OR PCR TESTS THAT 
                MAY BE DIRECTLY OR INDIRECTLY SUPPLIED TO YOU BY THE COMPANY, INCLUDING WITHOUT LIMITATION THE COVCLEAR 
                COVID-19 RAPID ANTIGEN TEST.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >9. LIMITATION OF LIABILITY</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >YOU AGREE THAT THE COMPANY, OUR DIRECTORS, OFFICERS, EMPLOYEES OR OTHER REPRESENTATIVES SHALL NOT BE 
                LIABLE FOR DAMAGES ARISING FROM THE OPERATION, CONTENT, ACCESS TO OR USE OF THE APPLICATION. YOU AGREE 
                THAT THIS LIMITATION OF LIABILITY, IS COMPREHENSIVE AND APPLIES TO ALL DAMAGES OF ANY KIND, INCLUDING 
                WITHOUT LIMITATION DIRECT, INDIRECT, COMPENSATORY, SPECIAL, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL 
                DAMAGES INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER 
                INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) HOWEVER CAUSED AND 
                ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR 
                OTHERWISE) ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE APPLICATION, OR IN RELIANCE ON THE DATA 
                OR OTHER INFORMATION AVAILABLE ON THE APPLICATION. IN NO EVENT SHALL OUR MAXIMUM AGGREGATE LIABILITY 
                (WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, STRICT LIABILITY OR 
                OTHER THEORY) ARISING OUT OF OR RELATING TO YOUR USE OF THE APPLICATION EXCEED THE AMOUNT OF CDN $100.
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >Certain laws do not allow limitations on implied warranties, or the exclusion or limitation of certain 
                damages. If these laws apply, some or all of the above disclaimers, exclusions, or limitations, may not 
                apply to you, and you may have additional rights to those contained herein.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >10. INDEMNIFICATION</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >You shall indemnify, defend and hold harmless the Company and our officers, directors, employees, 
                consultants, representatives and agents from and against any loss, liability, claim, cause of action, 
                demand, damages, costs or expenses including without limitation reasonable legal, accounting and other 
                professional fees, brought by or on your behalf in excess of the liability described herein or by third 
                parties arising from or in connection with your use of this Application, including but not limited any 
                violation by you of the Terms of Use. We reserve the right, to assume the exclusive defense and control 
                of any matter, subject to indemnification by you, which shall not excuse your indemnity obligations.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >11. LINKS</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >This Application may contain links to other web sites which are provided solely as a convenience to you 
                and the inclusion of any link does not imply endorsement, investigation or verification by us of the 
                linked web site or information contained therein. We shall not be responsible for the content of any 
                other web sites and makes no representation or warranty regarding any other web sites or the contents 
                or materials on such web sites. If you decide to access other web sites, you do so at your own risk.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >12. RELATIONSHIP</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >The relationship between the Company and you is not construed to be that of partners, joint ventures, 
                fiduciaries, employees or agents of the other as a result of these Terms of Use or use of this 
                Application.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >13. GOVERNING LAW</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >These Terms of Use and the performance hereof shall be governed by the laws of the Province of British 
                Columbia, Canada without regard to its conflict of law provisions. You consent and submit to the 
                exclusive jurisdiction of the courts located in the Province of British Columbia, Canada, in all 
                disputes arising out of or relating to the use of this Application and this Terms of Use. 
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >14. ASSIGNMENT</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >You may not assign, convey, subcontract or delegate your rights, duties or obligations hereunder.</Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >15. SEVERABILITY</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >These Terms of Use shall be deemed severable. In the event that any provision is determined to be 
                unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted 
                by applicable law, and such determination shall not affect the validity and enforceability of any other 
                remaining provisions.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >16. HEADINGS</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >The headings used in the Terms of Use are included for convenience only and will not limit or otherwise 
                affect these Terms of Use.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >17. ENTIRE AGREEMENT</Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  style={{
                      marginBottom: 25
                  }}
                >The Terms of Use, together with those incorporated herein or referred to herein constitute the entire 
                agreement between us relating to the subject matter hereof, and it supersedes any prior understandings 
                or agreements (whether electronic, oral or written) regarding the subject matter, and may not be amended 
                or modified except in writing or by making such amendments or modifications available on this 
                Application.
                </Typography>
            </Container>
        );
    }
};

export default withStyles(styles, { withTheme: true })(TermsConditions);