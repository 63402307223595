import React, { Component, forwardRef } from "react";
import { firebase } from "../firebase-config";
import { AuthContext } from "../context/AuthContext";
import moment from "moment";
// UI
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

// Icons
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import BackupIcon from "@material-ui/icons/Backup";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

//Table Component
// import { DataGrid, GridToolbarExport } from "@material-ui/data-grid";
import MaterialTable from "material-table";

// To PRINT
import ReactToPrint from "react-to-print";
import { ToPrint } from "./ToPrint";

//icons
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PrintIcon from "@material-ui/icons/Print";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TimerIcon from '@material-ui/icons/Timer';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';

// Components
import PatientModal from "./EditPatModal";
import AddPatientModal from "./AddPatientModal";
import DeletePatModal from "./DeletePatModal";
import { Delete, PhotoCamera } from "@material-ui/icons";
import SnackAlert from "./SnackAlert";

// Excel
import * as XLSX from 'xlsx/xlsx.mjs';
import ResultsModal from "./ResultsModal";

const styles = (theme) => ({
  heroContent: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  heroHeader: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  heroHeaderLoading: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(10),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgb(0 0 0 / 80%)",
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  PrintIcon: forwardRef((props, ref) => <PrintIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export class Project extends Component {
  constructor(props) {
    super();
    this.state = {
      project: {},
      projectPatientsDetail: [],
      projectData: [],
      selectedRows: [],
      patientDataToPrint: [],
      timeToSelect: {},
      patients: [],
      isLoadingProject: true,
      isLoadingPrint: false,
      isEditPatOpen: false,
      isDeletePatOpen: false,
      patient: {},
      openSnackbar: false,
      snackAlertType: "",
      snackAlertMsg: "",
      isAddPatientModalOpen: false,
      isLoadingDataTable: false,
      isResultsModalOpen: false,
    };
  }

  componentDidMount() {
    this.getProject();
  }

  getProject() {
    let projectId = this.props.match.params.id;
    let docRef = firebase.firestore().collection("projects").doc(projectId);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            project: doc.data(),
            isLoadingDataTable: false,
          });
          this.getProjectPatients();
        } else {
          console.log("Cant find this project");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  async getProjectPatients() {
    // If project has patients
    if (this.state.project.patients !== undefined) {
      let projectPatIdArray = [];
      this.state.project.patients.forEach((patient) => {
        projectPatIdArray.push(patient.id);
      });
      this.getContentById(projectPatIdArray, "patients");
    } else {
      this.setState({
        isLoadingProject: false,
      });
      console.log("Project with no patients");
    }
  }

  getContentById(ids, path) {
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length || !path) {
        this.setState({
          isLoadingProject: false,
        });
        return res([]);
      }

      const collectionPath = firebase.firestore().collection(path);
      let batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);
        // add the batch request to to a queue
        batches.push(
          new Promise((response) => {
            collectionPath
              .where(firebase.firestore.FieldPath.documentId(), "in", [
                ...batch,
              ])
              .get()
              .then((results) =>
                response(results.docs.map((result) => ({ ...result.data() })))
              );
          })
        );
      }

      // after all of the data is fetched, return it
      Promise.all(batches).then((content) => {
        this.setState({
          projectPatientsDetail: content.flat(),
          isLoadingProject: false,
        });
        this.getDataTable();
        res("done");
      });
    });
  }

  // format data, matching patient and project
  getDataTable() {
    let ppd = this.state.projectPatientsDetail;
    let pp = this.state.project.patients;
    const testType = this.state.project.testType;

    ppd.forEach((key, index) => {
      pp.forEach((bkey, bindex) => {
        if (key.id === bkey.id) {
          // here we add the time for this project
          ppd[index].time = bkey.time;
          // Format DOB
          ppd[index].dob_raw = key.dob;
          if (key.dob !== "") {
            ppd[index].dob = new Date(key.dob.replace(/-/g, "/"))
              .toDateString()
              .slice(4);
          } else {
            ppd[index].dob = "";
          }
          // Add Result if don't exist
          ppd[index].result = bkey.result !== undefined
            ? bkey.result
            : testType === 'rapid' || testType === 'rapid-supervised'
              ? "NEG"
              : "ND";
          // Add Booked at if don't exist
          ppd[index].bookedAt =
            bkey.bookedAt !== undefined
              ? new Date(bkey.bookedAt).toUTCString().toString().slice(0, 22)
              : "---";
          // photo for rapid tests
          ppd[index].hasPhoto = (bkey.hasPhoto);
          ppd[index].isHighlighted = (bkey.isHighlighted);
        }
      });
    });

      // currentUser.admin
      // default sort by first_name so we can print in ascending order
      ppd.sort((a, b) => (a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0));
      // default sort for Doctor last_name so we can print in ascending order
      // ppd.sort((a, b) => (a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0));

    let tempTimeToSelect = this.getTimeRange();

    this.setState({
      projectData: ppd,
      timeToSelect: tempTimeToSelect,
    });
  }

  // sortPatientsByLastName() {
  //   let patients = [...this.state.projectData];
  //   patients.sort((a, b) => (a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0));
  //   this.setState({
  //     projectData: patients,
  //   });
  //   console.log('Data sorted by last name');
  // }

  handleUploadCSV = (event) => {
    this.setState({
      isLoadingDataTable: true,
    });
    let myFile = event.target.files[0];
    let reader = new FileReader();
    let parsedData = [];
    let self = this;
    const getParsecsvdata = function (data) {
      let newLinebrk = data.split("\n");
      for (let i = 0; i < newLinebrk.length; i++) {
        let fullPatientData = newLinebrk[i].split(",");
        fullPatientData[8] = fullPatientData[8].split("\r")[0];
        fullPatientData[5] = fullPatientData[5].replace(/\s/g, "");
        parsedData.push(fullPatientData);
      }
      return parsedData;
    };
    reader.readAsBinaryString(myFile);
    let promise = new Promise(function (resolve, reject) {
      reader.addEventListener(
        "load",
        function (e) {
          let result = getParsecsvdata(e.target.result);
          resolve(result);
        },
        false
      );
    });
    promise.then(function (event) {
      self.uploadPatients(event);
    });
  };

  uploadPatients = (data) => {
    data.forEach(async (element, index) => {
      let elementFirstName =
        element[1].charAt(0).toUpperCase() + element[1].slice(1);

      let elementGender =
        element[6].charAt(0).toLowerCase() + element[6].slice(1);

      let patientFound = {};
      firebase
        .firestore()
        .collection("patients")
        .where("email", "==", element[8])
        .where("first_name", "==", elementFirstName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().id) {
              // patient already exists
              patientFound = doc.data();
            }
          });
          return patientFound;
        })
        .then((existentPatient) => {
          // Patient don't exist, so we create a new one
          if (Object.keys(existentPatient).length === 0) {
            let newPatientRef = firebase
              .firestore()
              .collection("patients")
              .doc();

            let newPatientData = {
              id: newPatientRef.id,
              first_name: elementFirstName,
              last_name: element[2],
              dob: element[3],
              email: element[8],
              phone: element[7],
              address: element[4],
              postal_code: element[5],
              gender: elementGender !== "" ? elementGender : "other",
            };

            return newPatientRef.set(newPatientData).then(() => {
              return this.addPatientToProject(newPatientRef.id, element[0]);
            });
            // Patient Already exist so we add to the project
          } else {
            return this.addPatientToProject(existentPatient.id, element[0]);
          }
        })
        .then((returnedData) => {
          // after last one update data table
          if (data.length === index + 1) {
            this.getProject();
          }
        });
    });
  };

  addPatientToProject = (patientId, time) => {
    let newProjectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.props.match.params.id);
    
    const testType = newProjectRef.testType;

    return newProjectRef
      .update({
        patients: firebase.firestore.FieldValue.arrayUnion({
          id: patientId,
          time: time,
          result: testType === 'rapid' || testType === 'rapid-supervised' ? "NEG" : "ND",
          tersmsConditions: "",
        }),
      })
      .then(() => {
        return "done";
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  editPatient = (event, rowData) => {
    let newPatEdited = {
      address: rowData.address,
      dob: rowData.dob_raw,
      email: rowData.email,
      first_name: rowData.first_name,
      gender: rowData.gender,
      id: rowData.id,
      last_name: rowData.last_name,
      phone: rowData.phone,
      postal_code: rowData.postal_code,
    };
    this.setState({
      isEditPatOpen: true,
      patient: newPatEdited,
    });
  };

  showPhoto = (event, rowData) => {
    
    this.setState({
      isResultsModalOpen: true,
    });

    var storage = firebase.storage();
    var storageRef = storage.ref();
    storageRef.child("test-results/" + this.state.project.id + "/" + rowData.id + ".jpg").getDownloadURL()
    .then((url) => {
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        // var blob = xhr.response;
        console.log('Photo Loaded');
      };
      xhr.open('GET', url);
      xhr.send();

      // Or inserted into an <img> element
      var img = document.getElementById('testResults');
      img.setAttribute('src', url);
      var spinner = document.getElementById('testResultsSpinner');
      spinner.remove();
      
    })
    .catch((error) => {
      console.log('Error displaying photo');
    });
  };
  
  closeResultsModal  = () => {
    this.setState({
      isResultsModalOpen: false,
    });
  };

  hasPhoto = (patient) => {
    return !patient.hasPhoto;
  };

  confirmDeletePatient = (event, rowData) => {
    let newPatEdited = {
      first_name: rowData.first_name,
      id: rowData.id,
    };
    this.setState({
      isDeletePatOpen: true,
      patient: newPatEdited,
    });
  };

  printPatient = (event, rowData) => {
    this.setState(
      {
        patientDataToPrint: [],
      },
      () => {
        this.setState({
          patientDataToPrint: [...this.state.patientDataToPrint, rowData],
        });
      }
    );
  };
  onBeforeGetContent = async () => {
    this.setState({
      isLoadingPrint: true,
    });
    return await new Promise(
      function (resolve) {
        setTimeout(() => {
          this.setState({
            isLoadingPrint: false,
          });
          resolve();
        }, 2000);
      }.bind(this)
    );
  };

  deletePatientFromProject = () => {
    let patientsArray = [...this.state.project.patients];

    patientsArray.forEach((element, index) => {
      if (element.id === this.state.patient.id) {
        patientsArray.splice(index, 1);
      }
    });

    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.project.id);

    projectRef
      .update({
        patients: patientsArray,
      })
      .then(() => {
        this.onActionDone();
      })
      .catch((error) => {
        console.log(error);
        this.onActionDone();
      });
  };

  closePatModal = () => {
    this.setState({
      isEditPatOpen: false,
      isDeletePatOpen: false,
    });
  };

  updatePatient = () => {
    this.setState({
      isEditPatOpen: false,
      isDeletePatOpen: false,
      openSnackbar: true,
      snackAlertType: "success",
      snackAlertMsg: "Patient updated successfully",
    });
    this.getProject();
  };

  onActionDone = () => {
    this.setState({
      isDeletePatOpen: false,
      isAddPatientModalOpen: false,
    });
    this.getProject();
  };

  handlePatientModal = () => {
    this.setState({
      isAddPatientModalOpen: !this.state.isAddPatientModalOpen,
    });
  };

  handleCloseAlert = (event) => {
    this.setState({
      openSnackbar: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  async togglePatientHighlight(rowData) {
    const patId = rowData.id;

    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.project.id);

    let patientsArray = await projectRef.get().then((doc) => {
        if (doc.exists) {
          return doc.data().patients;
        }
        return [];
    });

    patientsArray.filter((pat) => {
      if (pat.id === patId) {
        pat.isHighlighted = pat.isHighlighted !== undefined ? !pat.isHighlighted : true;
        return pat;
      }
      return pat;
    });

    projectRef
    .update({
      patients: patientsArray,
    })
    .then(() => {
      const clonedData = [...this.state.projectData];
      clonedData[rowData.tableData.id].isHighlighted = rowData.isHighlighted !== undefined ? !rowData.isHighlighted : true;
      this.setState({
        projectData: clonedData,
        openSnackbar: true,
        snackAlertType: "success",
        snackAlertMsg: "Patient highlight status saved",
      });
    })
    .catch((error) => {
      this.setState({
        openSnackbar: true,
        snackAlertType: "error",
        snackAlertMsg: "Sorry! Patient highlight status NOT saved",
      });
    });
  }

  async updateRow(newValue, rowData, columnDef) {
    let patId = rowData.id;

    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.project.id);

    let patientsArray = await projectRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data().patients;
      }
      return [];
    });
    

    let patientSlot =
      this.state.project.patientSlot !== undefined
        ? this.state.project.patientSlot
        : 4;

    if (columnDef.field === "time") {
      if (
        patientsArray.filter((v) => v.time === newValue).length < patientSlot
      ) {
        patientsArray.filter((pat) => {
          if (pat.id === patId) {
            pat.time = newValue;
            return pat;
          }
          return pat;
        });
      } else {
        this.setState({
          openSnackbar: true,
          snackAlertType: "warning",
          snackAlertMsg: "Can't update, time slot already full",
        });
        return [];
      }
    } else {
      patientsArray.filter((pat) => {
        if (pat.id === patId) {
          pat.result = newValue;
          return pat;
        }
        return pat;
      });
    }

    projectRef
      .update({
        patients: patientsArray,
      })
      .then(() => {
        // udpate the table data
        const clonedData = [...this.state.projectData];
        clonedData[rowData.tableData.id][columnDef.field] = newValue;

        this.setState({
          projectData: clonedData,
          openSnackbar: true,
          snackAlertType: "success",
          snackAlertMsg: "Updated successfully",
        });
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackAlertType: "error",
          snackAlertMsg: "Sorry! we have some technical issues",
        });
      });
  }

  getTimeRange = () => {
    let times = {};
    var d = new Date();
    d.setHours("00");
    d.setMinutes("00");
    var keepGoing = true;

    while (keepGoing) {
      let m1 = ((((d.getMinutes() + 7.5) / 15) | 0) * 15) % 60;
      let h1 = (((d.getMinutes() / 105 + 0.5) | 0) + d.getHours()) % 24;
      d = new Date(d.getYear(), d.getMonth(), d.getDay(), h1, m1, 0, 0);
      let time = ("0" + h1).slice(-2) + ":" + ("0" + m1).slice(-2);
      d = new Date(d.getTime() + 15 * 60000);
      let tempTime = {};
      tempTime[time] = time;
      Object.assign(times, tempTime);
      if (time === "23:45") {
        keepGoing = false;
      }
    }

    return times;
  };
  
  exportExcelFile = (patientHeader, patientData) => {
    const excelData = patientData.map(row => {
      const data = {
        'Time': row.time,
        'Result': row.result,
        'First Name': row.first_name,
        'Last Name': row.last_name,
        'Gender': row.gender,
        'DOB': row.dob,
        'Phone': row.phone,
        "Email": row.email,
        'Address': row.address,
        'Booked At': row.bookedAt,
      };

      return data;
    });
    
    let ws = XLSX.utils.json_to_sheet(excelData);
    // console.log(excelData.length);
		let wb = XLSX.utils.book_new(); 
		
    // let sheetName = ws.sheetName;
    
    // console.log(ws);

    // for (let i = 1; i <= excelData.length; i++) {
    //   ws.["A"+i].s = {
    //     fill: {
    //       // patternType: "solid",
    //       bgColor: "#F0E68C"
    //     }
    //   };
    // }

    XLSX.utils.book_append_sheet(wb, ws, "Patients");
		XLSX.writeFile(wb, "patients.xlsx", {cellStyles: true});
  };

  exportResults = (patientData, project) => {
    const resultOptions = {
      "N/A": "No Result",
      "NEG": "Negative",
      "POS": "Positive",
      "INV": "Invalid",
    };
    const dataTransformed = patientData.map(patient => {
      const resultData = {
        'First Name': patient.first_name,
        'Last Name': patient.last_name,
        'Result': resultOptions[patient.result],
        'Self Administered Collection Date': moment(patient.bookedAt).format("ddd, MMM DD YYYY"),
      };

      return resultData;
    });
    const ws = XLSX.utils.json_to_sheet(dataTransformed);
		const wb = XLSX.utils.book_new(); 
		XLSX.utils.book_append_sheet(wb, ws, "rapid test results");
		XLSX.writeFile(wb, project.name + "-results.xlsx");
  };

  render() {
    const { classes } = this.props;
    const {
      project,
      projectData,
      patientDataToPrint,
      timeToSelect,
      isLoadingProject,
      isLoadingPrint,
      isLoadingDataTable,
      isEditPatOpen,
      patient,
      openSnackbar,
      snackAlertType,
      snackAlertMsg,
      isDeletePatOpen,
      isAddPatientModalOpen,
      isResultsModalOpen,
    } = this.state;

    const actionsTable = [
      {
        icon: () => {
          return <Edit style={{ color: "#008000" }} />;
        },
        tooltip: "Edit Patient",
        onClick: (event, rowData) => this.editPatient(event, rowData),
      },
      (rowData) => ({
        icon: () => {
          return <Delete color="secondary" />;
        },
        tooltip: "Delete Patient",
        onClick: (event, rowData) => this.confirmDeletePatient(event, rowData),
      }),
      (rowData) => ({
        icon: () => (
          <ReactToPrint
            trigger={() => {
              return <PrintIcon color="primary" />;
            }}
            onBeforeGetContent={() => this.onBeforeGetContent()}
            content={() => this.printPatientRef}
          />
        ),
        // icon: PrintIcon,
        tooltip: "Print Patient",
        onClick: (event, rowData) => this.printPatient(event, rowData),
      }),
      (rowData) => ({
        icon: () => {
          return <PhotoCamera color="secondary" />;
        },
        tooltip: "Test Results",
        onClick: (event, rowData) => this.showPhoto(event, rowData),
        hidden: !rowData.hasPhoto
      }),
    ];

    return (
      <AuthContext.Consumer>
        {({ currentUser }) => {
          const adminColumns = [
            {
              field: "time",
              title: "Time",
              lookup: timeToSelect,
              hidden: currentUser.admin ? false : true,
            },
            {
              field: "result",
              title: "Result",
              lookup: project.testType !== 'rapid' && project.testType !== 'rapid-supervised' 
                ? {SD: "SD", ND: "ND"}
                : {INV: "INV", NEG: "NEG", POS: "POS"},
              editable: currentUser.admin ? "always" : "never",
            },
            {
              field: "first_name",
              title: "First name",
              editable: "never",
            },
            {
              field: "last_name",
              title: "Last name",
              editable: "never",
            },
            {
              field: "gender",
              title: "Gender",
              hidden: project.testType === 'rapid' || project.testType === 'rapid-supervised',
            },
            {
              field: "dob",
              title: "DOB",
              hidden: project.testType === 'rapid' || project.testType === 'rapid-supervised',
            },
            {
              field: "phone",
              title: "Phone",
              editable: "never",
            },
            {
              field: "email",
              title: "Email",
              editable: "never",
              hidden: project.testType === 'rapid' || project.testType === 'rapid-supervised',
            },
            {
              field: "address",
              title: "Address",
              editable: "never",
              hidden: project.testType === 'rapid' || project.testType === 'rapid-supervised',
            },
            
            {
              field: "bookedAt",
              title: "Booked Date",
              editable: "never",
              hidden: currentUser.admin ? false : true,
            },
          ];
          const doctorColumns = [
            {
              field: "time",
              title: "Time",
              lookup: timeToSelect,
              hidden: currentUser.admin ? false : true,
            },
            {
              field: "result",
              title: "Result",
              lookup: {SD: "SD", ND: "ND"},
              editable: "never",
            },
            {
              field: "last_name",
              title: "Last name",
              editable: "never",
            },
            {
              field: "first_name",
              title: "First name",
              editable: "never",
            },
            {
              field: "gender",
              title: "Gender",
            },
            {
              field: "dob",
              title: "DOB",
            },
            {
              field: "phone",
              title: "Phone",
              editable: "never",
            },
            {
              field: "email",
              title: "Email",
              editable: "never",
            },
            {
              field: "address",
              title: "Address",
              editable: "never",
            },
          ];
          return (
            <div>
              <main>
                <Backdrop className={classes.backdrop} open={isLoadingPrint}>
                  <CircularProgress size={100} thickness={2} />
                </Backdrop>

                {/* Hero unit */}
                {isLoadingProject ? (
                  <Container
                    maxWidth="lg"
                    className={classes.heroHeaderLoading}
                  >
                    <Box display="flex">
                      <CircularProgress size={100} thickness={2} />
                    </Box>
                  </Container>
                ) : (
                  <div>
                    <Container maxWidth="xl" className={classes.heroHeader}>
                      <Typography
                        component="h1"
                        variant="h4"
                        align="left"
                        color="textPrimary"
                        gutterBottom
                      >
                        {project.name}
                      </Typography>
                      <Box
                        style={{ width: "100%" }}
                        display="flex"
                        flexDirection="colunm"
                      >
                        <Box
                          style={{ width: "25%" }}
                          display="flex"
                          flexDirection="column"
                        >
                          {/* date   */}
                          <Typography
                            variant="body1"
                            style={{ display: "flex" }}
                          >
                            <Box component="span" mr={1}>
                              <EventAvailableIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Box>
                            <Box component="span">
                              {new Date(
                                project.date.replace(/-/g, "/")
                              ).toDateString()}
                            </Box>
                          </Typography>
                          {/* time   */}
                          { project.time.to !== "00:00" && project.time.from !== "00:00" ?
                          <Typography
                            variant="body1"
                            style={{ display: "flex" }}
                          >
                            <Box component="span" mr={1}>
                              <QueryBuilderIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Box>
                            <Box component="span">{project.time.from} </Box>
                            <Box component="span" ml={1} mr={1}>
                              -
                            </Box>
                            <Box component="span"> {project.time.to}</Box>
                          </Typography>
                          : null }
                          {/* location  */}
                          <Typography
                            variant="body1"
                            style={{ display: "flex" }}
                          >
                            <Box component="span" mr={1}>
                              <LocationOnIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Box>
                            <Box component="span">{project.location}</Box>
                          </Typography>
                          {/* Test Type */}
                          {project.testType === "rapid" || project.testType === "rapid-supervised" ? (
                          <Typography
                            variant="body1"
                            style={{ display: "flex" }}
                          >
                            <Box component="span" mr={1}>
                              <TimerIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Box>
                            <Box component="span">Rapid Test{ 
                            project.testType === "rapid-supervised" ? " - Supervised" : " - Step-by-step"
                            }</Box>
                          </Typography>
                          ) : null }
                        </Box>
                        <Box
                          style={{ width: "50%" }}
                          display="flex"
                          flexDirection="column"
                          pl={2}
                          pr={2}
                        >
                          {/* description  */}
                          <Typography
                            variant="body1"
                            style={{ display: "flex" }}
                          >
                            {project.description}
                          </Typography>
                        </Box>
                        <Box
                          style={{ width: "25%" }}
                          display="flex"
                          flexDirection="column"
                        >
                          {/* Print all  */}
                          { projectData.length > 0 
                            && currentUser.admin 
                            && project.testType !== 'rapid' 
                            && project.testType !== 'rapid-supervised' 
                          ? (
                            <Box display="flex" flexDirection="column">
                              <ReactToPrint
                                trigger={() => {
                                  return (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                      size="large"
                                    >
                                      <PrintIcon fontSize="small" />
                                      <Box component="span" ml={1}>
                                        Print All Patients
                                      </Box>
                                    </Button>
                                  );
                                }}
                                content={() => this.componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <ToPrint
                                  projectData={projectData}
                                  project={project}
                                  ref={(el) => (this.componentRef = el)}
                                />
                              </div>
                            </Box>
                          ) : project.testType === 'rapid' || project.testType === 'rapid-supervised' ?
                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                size="large"
                                onClick={ () => {this.exportResults(projectData, project) } }
                              >
                                <CloudDownloadOutlined fontSize="small" />
                                  <Box component="span" ml={1}>
                                    Export Results
                                  </Box>
                              </Button>
                              : null
                          }
                        </Box>
                      </Box>
                    </Container>
                    {/* Table */}
                    <Container maxWidth="xl" className={classes.tableContainer}>
                      <div style={{ width: "100%" }}>
                        <MaterialTable
                          title=""
                          columns={ currentUser.admin ? adminColumns : doctorColumns }
                          data={projectData}
                          isLoading={isLoadingDataTable}
                          options={{
                            search: true,
                            exportButton: {
                              csv: true,
                              pdf: true,
                            },
                            exportCsv: this.exportExcelFile,
                            sorting: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, projectData.length],
                            searchFieldStyle: {
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "5px",
                              borderRadius: "5px",
                            },
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                              backgroundColor: 
                              rowData.isHighlighted ? "#F0E68C" : null,
                            })
                          }}
                          localization={{
                            toolbar: {
                              exportCSVName: "Download Excel",
                              exportPDFName: "Download PDF"
                            }
                          }}
                          actions={
                            currentUser.admin 
                            // && project.date >= moment().format("YYYY-MM-DD")
                              ? actionsTable
                              : null
                          }
                          cellEditable={{
                            onCellEditApproved: (
                              newValue,
                              oldValue,
                              rowData,
                              columnDef
                            ) => {
                              return this.updateRow(
                                newValue,
                                rowData,
                                columnDef
                              );
                            },
                          }}
                          icons={tableIcons}
                          onRowClick={(event, rowData) => {
                                this.togglePatientHighlight(rowData);
                            // this.state.selectedRows.includes(rowData.tableData.id) 
                            // ? this.state.selectedRows.splice(
                            //     this.state.selectedRows.indexOf(rowData.tableData.id),
                            //     1
                            //   )
                            // : this.state.selectedRows.push(rowData.tableData.id);
                            // this.setState({selectedRows: this.state.selectedRows});
                            }}
                        />
                      </div>
                      <PatientModal
                        open={isEditPatOpen}
                        patient={patient}
                        onClose={this.closePatModal}
                        onUpdate={this.updatePatient}
                      />
                      <DeletePatModal
                        open={isDeletePatOpen}
                        patient={patient}
                        onClose={this.closePatModal}
                        deletePatient={this.deletePatientFromProject}
                      />
                      <AddPatientModal
                        open={isAddPatientModalOpen}
                        project={project}
                        onClose={this.handlePatientModal}
                        onActionDone={this.onActionDone}
                      />
                      <ResultsModal
                        open={isResultsModalOpen}
                        onClose={this.closeResultsModal}
                      />
                      <SnackAlert
                        open={openSnackbar}
                        type={snackAlertType}
                        message={snackAlertMsg}
                        handleCloseAlert={this.handleCloseAlert}
                      />
                    </Container>
                    <div style={{ display: "none" }}>
                      <ToPrint
                        projectData={patientDataToPrint}
                        project={project}
                        ref={(el) => (this.printPatientRef = el)}
                      />
                    </div>
                    {/* Action Buttons  */}
                    {currentUser.admin ? (
                      <Container maxWidth="xl" className={classes.heroContent}>
                        <Grid
                          container
                          alignItems="center"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {/* Upload Patient  */}
                          <Box display="flex" flexDirection="column" ml={2}>
                            <Button
                              variant="outlined"
                              color="default"
                              disableElevation
                              size="large"
                              component="label"
                            >
                              <BackupIcon fontSize="small" />
                              <Box component="span" ml={1}>
                                Upload Patients
                              </Box>
                              <input
                                type="file"
                                hidden
                                onChange={this.handleUploadCSV}
                              />
                            </Button>
                          </Box>
                          <Box display="flex" flexDirection="column" pl={2}>
                            <Button
                              variant="outlined"
                              color="default"
                              disableElevation
                              size="large"
                              onClick={this.handlePatientModal}
                            >
                              <AddCircleOutlineRoundedIcon fontSize="small" />
                              <Box component="span" ml={1}>
                                Add Patient
                              </Box>
                            </Button>
                          </Box>
                        </Grid>
                      </Container>
                    ) : null}
                  </div>
                )}
              </main>
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Project);
