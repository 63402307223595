import React, { Component } from "react";
// import { firebase } from "../firebase-config";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    paper: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      padding: 20,
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttons: {
      margin: 20,
    },
    alert: {
      width: "100%",
    },
  });
  
// const analytics = firebase.analytics();

export class ResultsModal extends Component {
    render() {
        const { onClose, open } = this.props;
        var img = document.getElementById('testResults');
        return (
        <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
            <DialogTitle style={{ marginBottom: 10, textTransform: "uppercase" }}>
              Test Results
            </DialogTitle>  
            <DialogContent>
                <Box style={{ textAlign: "center" }}>
                    <CircularProgress
                    size={100}
                    thickness={1}
                    style={{ color: "#C71E39" }}
                    id="testResultsSpinner"
                    />
                </Box>
                <img id="testResults" alt="Test Results" width="100%" />
            </DialogContent>
            <DialogActions style={{ padding: "24px" }}>
              <Button
                onClick={onClose}
                variant="outlined"
                size="large"
                disableElevation
                fullWidth
              >
                Close
              </Button>
            </DialogActions>
        </Dialog>
        </div>
        );
    };
}
export default withStyles(styles, { withTheme: true })(ResultsModal);