import React, { Component } from "react";
import { firebase } from "../firebase-config";

// UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: 20,
    marginTop: "50%",
    marginBottom: "10%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: 20,
    marginTop: "5%",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
});

// Firebase
const auth = firebase.auth();
const analytics = firebase.analytics();

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
    };
  }

  loginUser = () => {
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((response) => {
        const userId = response.user.uid;
        analytics.logEvent("login", { userId: userId });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          error: error.message,
        });
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.which === 13) {
      this.loginUser();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <Box className={classes.logo}></Box>
        {this.state.error && (
          <Alert
            severity="error"
            onClose={() => {
              this.setState({
                error: "",
              });
            }}
          >
            {this.state.error}
          </Alert>
        )}
        <Paper className={classes.paper}>
          <Box style={{ margin: "10px 0" }}>
            <Typography component="h1" variant="h5">
              Sign in to your account
            </Typography>
          </Box>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleChange}
            onKeyPress={this.handleKeypress}
          />
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            margin="normal"
            color="primary"
            onClick={this.loginUser}
            className={classes.submit}
            disableElevation
          >
            Continue
          </Button>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
