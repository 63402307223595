import React, { Component, forwardRef } from "react";
import { firebase } from "../firebase-config";

// UI
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Delete } from "@material-ui/icons";

//Table Component
// import { DataGrid } from "@material-ui/data-grid";
import MaterialTable from "material-table";

// Components
import PatientModal from "./EditPatModal";
import DeletePatModal from "./DeletePatModal";

const styles = (theme) => ({
  heroContent: {
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  heroHeader: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export class Patients extends Component {
  constructor(props) {
    super();
    this.state = {
      patients: [],
      isLoadingTable: true,
      isPatientModalOpen: false,
      isDeletePatOpen: false,
      patient: {},
      openSnackBar: false,
      snackAlertMsg: "",
      snackAlertType: "success",
    };
  }

  componentDidMount() {
    this.getPatients();
  }

  getPatients() {
    let allPatients = [];
    let patientsRef = firebase.firestore().collection("patients");
    patientsRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let patient = doc.data();
          console.log("1");
          // Format DOB
          patient.dob_raw = patient.dob;

          if (patient.dob !== "") {
            patient.dob = new Date(patient.dob.replace(/-/g, "/"))
              .toDateString()
              .slice(4);
          } else {
            patient.dob = "";
          }
          allPatients.push(patient);
        });
        return allPatients;
      })
      .then((data) => {
        this.setState({
          patients: data,
        });
      });
  }

  editPatient = (event, rowData) => {
    let editPatient = {
      address: rowData.address,
      dob: rowData.dob_raw,
      email: rowData.email,
      first_name: rowData.first_name,
      gender: rowData.gender,
      id: rowData.id,
      last_name: rowData.last_name,
      phone: rowData.phone,
      postal_code: rowData.postal_code,
    };

    this.setState({
      isPatientModalOpen: true,
      patient: editPatient,
    });
  };

  closePatModal = () => {
    this.setState({
      isPatientModalOpen: false,
      isDeletePatOpen: false,
    });
  };

  updatePatient = () => {
    this.setState({
      isPatientModalOpen: false,
      openSnackBar: true,
      snackAlertMsg: "Patient successfully updated",
      snackAlertType: "success",
    });
    this.getPatients();
  };

  handleCloseAlert = (event) => {
    this.setState({
      openSnackBar: false,
    });
  };

  confirmDeletePatient = (event, rowData) => {
    let newPatEdited = {
      first_name: rowData.first_name,
      id: rowData.id,
    };
    this.setState({
      isDeletePatOpen: true,
      patient: newPatEdited,
    });
  };

  deletePatient = () => {
    let patientRef = firebase
      .firestore()
      .collection("patients")
      .doc(this.state.patient.id);

    patientRef
      .delete()
      .then(() => {
        this.getPatients();
        this.closePatModal();
        this.setState({
          openSnackBar: true,
          snackAlertMsg: "Patient successfully deleted",
          snackAlertType: "success",
        });
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      patients,
      patient,
      isPatientModalOpen,
      openSnackBar,
      isDeletePatOpen,
      snackAlertMsg,
      snackAlertType,
    } = this.state;

    const columns = [
      {
        field: "first_name",
        title: "First name",
      },
      {
        field: "last_name",
        title: "Last name",
      },
      {
        field: "gender",
        title: "Gender",
      },
      {
        field: "dob",
        title: "DOB",
      },
      {
        field: "phone",
        title: "Phone",
      },
      {
        field: "email",
        title: "Email",
      },
      {
        field: "address",
        title: "Address",
      },
    ];

    return (
      <div>
        <main>
          <Container maxWidth="xl" className={classes.heroHeader}>
            <Typography
              component="h1"
              variant="h4"
              align="left"
              color="textPrimary"
            >
              All Patients
            </Typography>
          </Container>
          <Container maxWidth="xl" className={classes.tableContainer}>
            <div style={{ width: "100%" }}>
              <MaterialTable
                title=""
                columns={columns}
                data={patients}
                // onRowClick={this.onCellClick}
                options={{
                  search: true,
                  sorting: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50],
                  searchFieldStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "5px",
                    borderRadius: "5px",
                  },
                  actionsColumnIndex: -1,
                }}
                actions={[
                  {
                    icon: Edit,
                    tooltip: "Edit Patient",
                    onClick: (event, rowData) =>
                      this.editPatient(event, rowData),
                  },
                  (rowData) => ({
                    icon: Delete,
                    tooltip: "Delete Patient",
                    onClick: (event, rowData) =>
                      this.confirmDeletePatient(event, rowData),
                  }),
                ]}
                icons={tableIcons}
              />
              <PatientModal
                open={isPatientModalOpen}
                patient={patient}
                onClose={this.closePatModal}
                onUpdate={this.updatePatient}
              />
              <DeletePatModal
                open={isDeletePatOpen}
                patient={patient}
                onClose={this.closePatModal}
                deletePatient={this.deletePatient}
                message={"? It will be delete permanently."}
              />
              <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={this.handleCloseAlert}
              >
                <Alert severity={snackAlertType} elevation={6} variant="filled">
                  <span>{snackAlertMsg}</span>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseAlert}
                    style={{ marginLeft: 20 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Alert>
              </Snackbar>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Patients);
