import React, { useContext, useState, useEffect } from "react";
import { firebase } from "../firebase-config";

const auth = firebase.auth();
export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();

  function logout() {
    setCurrentUser(null);
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user.admin = idTokenResult.claims.admin;
          setCurrentUser(user);
        });
      }
    });
    return unsubscribe;
  }, []);

  const value = { currentUser, logout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
