import React, { Component } from "react";
import { firebase } from "../firebase-config";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: 20,
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttons: {
    margin: 20,
  },
  alert: {
    width: "100%",
  },
});

const analytics = firebase.analytics();
export class PatientModal extends Component {
  constructor(props) {
    super();
    this.state = {
      address: "",
      dob: "",
      email: "",
      first_name: "",
      gender: "",
      id: "",
      last_name: "",
      phone: "",
      postal_code: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      first_name: nextProps.patient.first_name,
      address: nextProps.patient.address,
      dob: nextProps.patient.dob,
      email: nextProps.patient.email,
      gender: nextProps.patient.gender,
      id: nextProps.patient.id,
      last_name: nextProps.patient.last_name,
      phone: nextProps.patient.phone,
      postal_code: nextProps.patient.postal_code,
    });
  }

  updatePatient = () => {
    let patienRef = firebase
      .firestore()
      .collection("patients")
      .doc(this.state.id);

    patienRef
      .update({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        dob: this.state.dob,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        postal_code: this.state.postal_code,
        gender: this.state.gender,
      })
      .then(() => {
        // Add to analytics
        analytics.logEvent("pat_updated_from_project", {
          patId: this.state.id,
        });
        this.props.onUpdate();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        this.props.onClose();
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { classes, onClose, open } = this.props;
    const {
      first_name,
      last_name,
      address,
      dob,
      email,
      gender,
      phone,
      postal_code,
    } = this.state;
    return (
      <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
          <ValidatorForm onSubmit={this.updatePatient}>
            <DialogTitle style={{ marginBottom: 10 }}>
              Edit Patient Information
            </DialogTitle>
            <DialogContent>
              <div>
                <Grid container spacing={2} className={classes.header}>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      name="first_name"
                      variant="outlined"
                      fullWidth
                      id="first_name"
                      label="First Name"
                      value={first_name}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      spellCheck="true"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      spellCheck="true"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      value={dob}
                      id="dob"
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      type="number"
                      id="phone"
                      value={phone}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="address"
                      label="Address"
                      id="address"
                      value={address}
                      onChange={this.handleChange}
                      spellCheck="true"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="postal_code"
                      label="Postal Code"
                      id="postal_code"
                      value={postal_code}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      required
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Prefer not to say"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "24px" }}>
              <Button
                onClick={onClose}
                variant="outlined"
                size="large"
                disableElevation
                fullWidth
              >
                Cancel
              </Button>
              <Button
                // onClick={this.updatePatient}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
              >
                Update
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PatientModal);
