import React, { Component } from "react";
// import { firebase } from "../firebase-config";
import { withStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    paper: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      padding: 20,
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttons: {
      margin: 20,
    },
    alert: {
      width: "100%",
    },
  });
  
// const analytics = firebase.analytics();

export class TermsModal extends Component {
    render() {
        const { onClose, open } = this.props;
        return (
        <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
            <DialogTitle style={{ marginBottom: 10, textTransform: "uppercase" }}>
              Terms and conditions
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ display: "flex" }}>
                Results from your rapid test will be shared with the production company.
                </Typography>
                <Typography variant="body1" style={{ display: "flex", marginTop: "10px" }}>
                Nullam id tristique mauris, sit amet luctus sem. Nulla suscipit, sapien at convallis scelerisque, velit erat fermentum arcu, in elementum ipsum felis nec purus. Donec luctus sapien vel ante luctus egestas. Cras consectetur posuere venenatis. Nullam eu nisi ut mauris aliquam condimentum. In nec tortor egestas, feugiat purus nec, pretium sapien. Donec felis nunc, tristique at odio et, fermentum pellentesque justo. Nunc aliquam sed libero eu efficitur. Mauris ac dignissim neque. Mauris commodo, orci non posuere cursus, libero neque posuere massa, eu condimentum arcu nibh sed risus. Ut ut maximus nibh. Mauris bibendum ultrices semper. 
                </Typography>
            </DialogContent>
            <DialogActions style={{ padding: "24px" }}>
              <Button
                onClick={onClose}
                variant="outlined"
                size="large"
                disableElevation
                fullWidth
              >
                Close
              </Button>
            </DialogActions>
        </Dialog>
        </div>
        );
    };
}
export default withStyles(styles, { withTheme: true })(TermsModal);