import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export class SnackAlert extends Component {
  render() {
    return (
      <div>
        <Snackbar
          open={this.props.open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.handleCloseAlert}
        >
          <Alert severity={this.props.type} elevation={6} variant="filled">
            <span>{this.props.message}</span>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={this.props.handleCloseAlert}
              style={{ marginLeft: 20 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default SnackAlert;
