import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDRMRraGRdzlgG8fdAsCGBscYnB6WiWPkY",
  authDomain: "set-protect.firebaseapp.com",
  projectId: "set-protect",
  storageBucket: "set-protect.appspot.com",
  messagingSenderId: "633453288157",
  appId: "1:633453288157:web:56384e3e63608db38e5934",
  measurementId: "G-2PPQN941Z2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const auth = firebase.auth();
export { firebase };
