import React, { Component } from "react";
// import { firebase } from "../firebase-config";

import { withStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const styles = (theme) => ({});

// Firebase Analytics
// const analytics = firebase.analytics();

export class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {

            }
    }
    
    componentDidMount() {
        console.log("Privacy Policy page loaded");
    }

    render() {
        return (
            <Container component="main" maxWidth="md">
                <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    style={{
                        marginTop: 25,
                        marginBottom: 25
                    }}
                >Set Protect Production Services Inc. Privacy Policy
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >1. COMMITMENT TO PRIVACY
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Set Protect Production Services Inc. (the "<b>Company</b>") is committed to maintaining the security and 
                privacy of your personal information that may be collected through your use of app.setprotect.ca (the 
                “<b>Application</b>”).  This Policy documents our ongoing commitment to you and has been developed in 
                compliance with the British Columbia Personal Information Protection Act ("<b>PIPA</b>").
                </Typography>
                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >2. SCOPE OF POLICY
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >In accordance with PIPA, this Policy addresses personal information about individuals to promote 
                responsible and transparent practices in the management of said personal information.  Commercial 
                information may be subject to protection under other polices and practices and through contractual 
                arrangements, including confidentiality agreements.  This Policy does not impose any limits on the 
                collection, use or disclosure of publicly available information recognized under PIPA.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >3. ACCOUNTABILITY</Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company is accountable and responsible for personal information under its control.  The Company has
                designated a Privacy Officer who is accountable for the Company’s compliance with this Policy.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will adopt procedures to protect personal information, receive and respond to complaints and inquiries, train staff regarding privacy polices and procedures and communicate polices and procedures to you. 
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >4. PURPOSE
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >
                The Company collects your personal information on the Application for the following purposes:
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                    style={{
                        marginLeft: 20
                    }}
                >
                <li>to provide services requested and to use and disclose the information for any purpose related to the provision of requested services;</li>
                <li>to determine which services may meet your potential needs;</li>
                <li>to evaluate PCR and/or self-administered rapid antigen COVID-19 assessments and applications;</li>
                <li>to protect the Company, yourself and others from fraud and error and to safeguard the interests of 
                    the Company;</li>
                <li>to authenticate your identity;</li>

                <li>to provide and disclose your personal information (which may include, without limitation, 
                    self-administered rapid antigen COVID-19 test kit results and PCR test results) to production 
                    companies and/or health authorities  in order to provide services requested by health authorities or 
                    production companies that engage the Company to facilitate COVID-19 testing;</li>
                <li>to collect debts owed to the Company, if any; and</li>
                <li>to comply with legal and regulatory requirements.</li>
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The purposes listed above are a reasonably necessary part of your relationship with the Company.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >When your personal information is to be used for a purpose not previously identified, the new purpose 
                will be disclosed to you prior to such use, and your consent will be sought unless the use is authorized 
                or required by PIPA or other law.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >5. CONSENT
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will obtain your consent to collect, use or disclose personal information except where the 
                Company is authorized or required by PIPA or other law to do so without consent.  
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Your consent may be express or implied, or given through your authorized representative.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Consent may be provided orally, in writing, electronically, through inaction (such as when you fail to 
                notify the Company that you do not wish your personal information collected/used/disclosed for optional 
                purposes following reasonable notice to you) or otherwise.  For example, oral consent could be expressed 
                over the telephone at the time information is being collected; electronically when submitting an 
                agreement, application form or other information; or in writing when signing an agreement or application
                form or other information. 
                </Typography>

                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >You may withdraw your consent at any time, subject to legal or contractual restrictions, provided 
                reasonable written notice of withdrawal of consent is given by you to the Company.  Upon receipt of your 
                written notice, the Company will inform you of the likely consequences of the withdrawal, which may 
                include the inability of the Company to provide certain services for which the delivery of that 
                information is a prerequisite. 
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >While the Company may delete personal information collected via the Application, certain personal 
                information may be stored by the Company’s clients or by medical professionals as part of your 
                electronic health record in accordance with applicable laws.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >6. LIMITS ON COLLECTION OF PERSONAL INFORMATION
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will not collect personal information indiscriminately and will limit its 
                collection of your personal information to what is reasonably necessary to provide a service and which 
                is reasonably necessary for the purposes consented to by you.  The Company may also collect information 
                as authorized by PIPA or other law.
                </Typography>  
                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >7. RETENTION OF PERSONAL INFORMATION
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Your personal information will only be used or disclosed for the purposes set out above and as 
                authorized by PIPA and other law.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will keep personal information used to make a decision affecting an individual for at least 
                one year after using it to make the decision.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will destroy, erase or anonymize documents or other records containing personal 
                information as soon as it is reasonable to assume that the original purpose is no longer being served by 
                retention of the information and retention is no longer necessary for legal or business purposes.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will take due care when destroying personal information so as to prevent unauthorized access 
                to such information.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >8. ACCURACY
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will make a reasonable effort to ensure that personal information it is using or disclosing 
                is accurate and complete.  The Company will rely on you to ensure that certain information, such as your 
                street address, e-mail address or telephone number, is current, complete and accurate.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >If you notify the Company of the inaccuracy or incompleteness of personal information, the Company will 
                amend the information as required.  If appropriate, the Company will send the amended information to 
                third parties to whom the information has been disclosed.
                </Typography>

                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >When a challenge regarding the accuracy of personal information is not resolved to your satisfaction, 
                the Company will annotate the personal information under its control with a note that a correction was 
                requested but not made.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >9. SAFEGUARDING PERSONAL INFORMATION
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company protects the personal information in its custody or control by making reasonable security 
                arrangements to prevent unauthorized access, collection, use, disclosure, copying, modification, disposal 
                or similar risks.  
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >You should be aware that confidentiality and security are not assured when information is transmitted 
                through e-mail or wireless communication.  The Company will not be responsible for any loss or damage 
                suffered as a result of a breach of security or confidentiality when information is transmitted by 
                e-mail or wireless communication.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >10. PROVIDING ACCESS
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >You have a right to access your personal information held by the Company.
                </Typography>

                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Upon written request and authentication of identity, the Company will provide you with personal 
                information under its control, information about the ways in which that information is being used and a 
                description of the individuals and organizations to whom such information has been disclosed.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company may charge a reasonable fee for providing personal information in response to a PIPA access 
                request and will provide an estimate of any such fee upon receiving a written access to personal 
                information request.  The Company may require a deposit for all or part of the fee.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The Company will make personal information available within 30 days or provide written notice where 
                additional time is required to fulfill the request.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >In some situations, the Company may not be able to provide access to certain personal information.  The 
                Company may also be prevented by law from providing access to certain personal information.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Where an access request is refused in whole or in part, the Company will notify you in writing, giving 
                the reason for refusal and outlining further steps which are available to you.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >11. CHANGES TO POLICY
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >To keep pace with economic and technological changes, the Company may revise this Policy from time to 
                time.  The revised Policy will be posted on our website, and will come into effect 30 days after it is 
                posted.
                </Typography>

                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >12. COMPLAINTS
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Any inquiry, complaint or question regarding this Privacy Policy must be directed in writing to the 
                Company’s Privacy Officer.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >Contact Information:
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                >Alice Ride
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                >Set Protect Production Services Inc.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                ><a href="mailto:hello@setprotect.ca">hello@setprotect.ca</a>
                </Typography>


                <Typography 
                    variant="body1" 
                    component="p" 
                    gutterBottom
                >13. DEFINITIONS
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >In this Policy:
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >The terms "<b>Company</b>” or "<b>our</b>" refer to Set Protect Production Services Inc., a company 
                duly incorporated under the laws of British Columbia ;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>collection</b>" means the act of gathering, acquiring, or obtaining personal information from any 
                source, including third parties, by any means;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>consent</b>" means voluntary agreement to the collection, use and disclosure of personal information 
                for specified purposes;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>disclosure</b>" means making personal information available to a third party;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>personal information</b>" means information about an identifiable individual but does not include 
                his or her business contact information.  Personal information does not include (a) information 
                concerning corporate or commercial entities or (b) publicly available information.  It also does not 
                include information that cannot be associated with a specific individual;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>PIPA</b>" means the British Columbia Personal Information Protection Act, S.B.C. 2003, c.63, as 
                amended;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>Privacy Officer</b>" means an individual designated by the Company who is accountable for compliance 
                with this Policy by the Company and whose contact particulars are set forth in this Policy;
                </Typography>

                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>third party</b>" means an individual or organization other than the Company and you;
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                >"<b>use</b>" means the treatment and handling of personal information by and within the Company.
                </Typography>
                <Typography 
                    variant="body2" 
                    component="p" 
                    gutterBottom
                    style={{
                        marginBottom: 25
                    }}
                >Version #001 (May 2022)
                </Typography>
            </Container>
        );
    }
};

export default withStyles(styles, { withTheme: true })(PrivacyPolicy);