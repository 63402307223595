import React, { Component } from "react";
import { firebase } from "../firebase-config";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: 20,
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  header: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttons: {
    margin: 20,
  },
  alert: {
    width: "100%",
  },
  row: {
    borderBottom: "1px solid #e0e0e0",
    minHeight: "60px",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
});

// Firebase Analytics
const analytics = firebase.analytics();

export class PatientModal extends Component {
  constructor(props) {
    super();
    this.state = {
      input: "",
      patients: "",
      project: "",
      tabSelected: "search",
      // Form data
      pat_time: "00:00",
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone: "",
      address: "",
      postal_code: "",
      gender: "female",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      project: nextProps.project,
    });
  }

  searchPatient = () => {
    const searchString =
      this.state.input.charAt(0).toUpperCase() + this.state.input.slice(1);

    const patientsRef = firebase.firestore().collection("patients");

    const patientsArray = [];
    patientsRef
      .orderBy("first_name", "asc")
      .where("first_name", ">=", searchString)
      .where("first_name", "<=", searchString + "\uf8ff")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          patientsArray.push(doc.data());
          // console.log(doc.id, " => ", doc.data());
        });
        return;
      })
      .then(() => {
        this.setState({
          patients: patientsArray,
        });
      });
  };

  async addPatientToProject(patId) {
    if (
      this.state.project.patients !== undefined &&
      this.state.project.patients.filter((pat) => pat.id === patId).length === 1
    ) {
      this.props.onActionDone();
    } else {
      let projectRef = firebase
        .firestore()
        .collection("projects")
        .doc(this.state.project.id);
      
      const testType = this.state.project.testType;
      projectRef
        .update({
          patients: firebase.firestore.FieldValue.arrayUnion({
            id: patId,
            time: this.state.pat_time,
            bookedAt: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            ).toUTCString(),
            result: testType === 'pcr' ? "ND" : "NEG",
            tersmsConditions: "",
          }),
          patientTotal:
            this.state.project.patientTotal !== undefined
              ? this.state.project.patientTotal + 1
              : 1,
        })
        .then(() => {
          this.setState({
            input: "",
            patients: "",
          });
          this.props.onActionDone();

          // Add to analytics
          analytics.logEvent("pat_add_from_project", {
            projectId: this.state.project.id,
          });
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }

  handleSubmit = () => {
    let newPatientRef = firebase.firestore().collection("patients").doc();

    let newPatientData = {
      id: newPatientRef.id,
      first_name:
        this.state.first_name.charAt(0).toUpperCase() +
        this.state.first_name.slice(1),
      last_name: this.state.last_name,
      dob: this.state.dob,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      postal_code: this.state.postal_code,
      gender: this.state.gender,
    };

    // Create the new patient.
    newPatientRef.set(newPatientData).then(() => {
      // Set patient ont the project
      this.addPatientToProject(newPatientRef.id);
      // Add to analytics
      analytics.logEvent("pat_created_from_project", {
        patientId: newPatientRef.id,
      });
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changeTab = (tab) => {
    if (tab === "search") {
      this.setState({
        tabSelected: "search",
      });
    } else {
      this.setState({
        tabSelected: "create",
      });
    }
  };

  render() {
    const { classes, onClose, open } = this.props;
    const {
      tabSelected,
      input,
      patients,
      first_name,
      last_name,
      dob,
      email,
      phone,
      address,
      postal_code,
      gender,
    } = this.state;
    return (
      <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
          <ButtonGroup
            size="large"
            color="primary"
            fullWidth
            style={{ marginBottom: "30px" }}
          >
            <Button
              disableElevation
              variant={tabSelected === "search" ? "contained" : "outlined"}
              style={{ borderRadius: 0 }}
              onClick={() => this.changeTab("search")}
            >
              Search for Patients
            </Button>
            <Button
              disableElevation
              variant={tabSelected === "create" ? "contained" : "outlined"}
              style={{ borderRadius: 0 }}
              onClick={() => this.changeTab("create")}
            >
              Create a new Patient
            </Button>
          </ButtonGroup>
          <DialogContent>
            {tabSelected === "search" ? (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="input"
                      label="Search for patient First Name"
                      name="input"
                      autoFocus
                      value={input}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DialogActions style={{ padding: "0" }}>
                      <Button
                        onClick={onClose}
                        variant="outlined"
                        size="large"
                        disableElevation
                        fullWidth
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        margin="normal"
                        color="primary"
                        className={classes.submit}
                        disableElevation
                        fullWidth
                        onClick={this.searchPatient}
                        disabled={input !== "" ? false : true}
                      >
                        Search for patients
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>

                {patients.length > 0 ? (
                  <Container className={classes.header} style={{ padding: 0 }}>
                    <Grid
                      container
                      // spacing={3}
                      className={classes.row}
                      direction="row"
                      alignItems="center"
                      justifycontent="space-between"
                    >
                      <Grid item xs={2}>
                        <Typography>First Name</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Last Name</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Gender</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>DOB</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>Email</Typography>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        <Typography>Action</Typography>
                      </Grid>
                    </Grid>
                    {patients.map((patient, index) => (
                      <Grid
                        container
                        // spacing={3}
                        className={classes.row}
                        direction="row"
                        alignItems="center"
                        justifycontent="space-between"
                        key={index}
                      >
                        <Grid item xs={2}>
                          <Typography>{patient.first_name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{patient.last_name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{patient.gender}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>
                            {patient.dob !== ""
                              ? new Date(patient.dob.replace(/-/g, "/"))
                                  .toDateString()
                                  .slice(4)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{patient.email}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          <IconButton
                            aria-label="Add"
                            className={classes.margin}
                            onClick={() => this.addPatientToProject(patient.id)}
                          >
                            <AddCircleIcon color="primary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Container>
                ) : null}
              </div>
            ) : (
              <ValidatorForm onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      name="first_name"
                      variant="outlined"
                      fullWidth
                      id="first_name"
                      label="First Name"
                      value={first_name}
                      onChange={this.handleChange}
                      spellCheck="true"
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                      spellCheck="true"
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      value={dob}
                      id="dob"
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      type="number"
                      id="phone"
                      value={phone}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="address"
                      label="Address"
                      id="address"
                      value={address}
                      onChange={this.handleChange}
                      spellCheck="true"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="postal_code"
                      label="Postal Code"
                      id="postal_code"
                      value={postal_code}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      required
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Prefer not to say"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <DialogActions style={{ padding: "0" }}>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    size="large"
                    disableElevation
                    fullWidth
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    margin="normal"
                    color="primary"
                    className={classes.submit}
                    disableElevation
                    fullWidth
                  >
                    Add Patient to Project
                  </Button>
                </DialogActions>
              </ValidatorForm>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PatientModal);
