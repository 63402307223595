import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
// ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ContactsRoundedIcon from "@material-ui/icons/ContactsRounded";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#fff",
    padding: "10px 0",
  },
  toolbar: {
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  logo: {
    height: "auto",
    cursor: "pointer",
    color: "#0080ff",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));

function Nav() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { currentUser, logout } = useAuth();
  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      console.log("error");
    }
  }

  return location.pathname.split("/")[3] !== "privacy-policy" &&
    location.pathname.split("/")[3] !== "terms-conditions" &&
    location.pathname.split("/")[3] !== "camera" &&
    location.pathname.split("/")[3] !== "form" &&
    location.pathname !== "/login" &&
    location.pathname !== "/" &&
    currentUser ? (
    <div>
      <CssBaseline />
      <AppBar
        position="sticky"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Box component={Link} to="/">
            <ContactsRoundedIcon className={classes.logo} />
          </Box>
          <div>
            <Button
              component={Link}
              to="/projects"
              color="primary"
              className={classes.link}
            >
              Projects
            </Button>
            {currentUser.admin ? (
              <Button
                component={Link}
                to="/patients"
                color="primary"
                className={classes.link}
              >
                Patients
              </Button>
            ) : null}
            <Button
              color="primary"
              className={classes.link}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  ) : null;
}

export default Nav;
