import React, { Component } from "react";
import { firebase } from "../firebase-config";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import moment from "moment";
// UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Container from "@material-ui/core/Container";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TimerIcon from '@material-ui/icons/Timer';
import { Checkbox, Link } from "@material-ui/core";
import { TermsModal } from "./TermsModal";
import { green } from "@material-ui/core/colors";

import Camera, {IMAGE_TYPES, FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const styles = (theme) => ({
  input: {
    "& label.Mui-focused": {
      color: "#C71E39",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#C71E39",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#C71E39",
      },
    },
  },
  radio: {
    "&$checked": {
      color: "#C71E39",
    },
  },
  checked: {},
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    padding: 20,
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: "#C71E39",
    "&:hover": {
      backgroundColor: "#931428",
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#C71E39",
  },
  alert: {
    width: "100%",
  },
});

// Firebase Analytics
const analytics = firebase.analytics();
export class PatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.match.params.id,
      project: [],
      pat_time: "",
      first_name: "",
      last_name: "",
      dob_month: "",
      dob_year: "",
      dob_day: "",
      dob: "",
      email: "",
      phone: "",
      address: "",
      postal_code: "",
      gender: "female",
      termsConditions: "",
      // actions
      formDisplay: "form",
      isSubmitting: false,
      isLoading: true,
      isAlertOpen: false,
      isTermsModalOpen: false,
      // patient details
      patId: "",
      activeStep: 0,
      testResult: "",
      photoUploaded: false,
    };
  }

  componentDidMount() {
    this.getProjectDetails();
    this.prePopulate();
  }

  prePopulate = () => {
    const queryStringPartner = window.location.search;
    const urlParamsPartner = new URLSearchParams(queryStringPartner);
    let first_name = urlParamsPartner.get("first_name");
    let last_name = urlParamsPartner.get("last_name");
    let dob = urlParamsPartner.get("dob");
    let email = urlParamsPartner.get("email");
    let phone = urlParamsPartner.get("phone");
    let address = urlParamsPartner.get("address");
    let postal_code = urlParamsPartner.get("postal_code");
    let gender = urlParamsPartner.get("gender");
    let termsConditions = urlParamsPartner.get('termsConditions');
    
    this.setState({
      first_name: first_name !== null ? first_name : "",
      last_name: last_name !== null ? last_name : "",
      email: email !== null ? email : "",
      phone: phone !== null ? phone : "",
      address: address !== null ? address : "",
      postal_code: postal_code !== null ? postal_code : "",
      gender: gender !== null ? gender : "other",
      termsConditions: termsConditions !== null ? termsConditions : "",
      dob_month: dob !== null && dob !== "" ? dob.split("-")[1] : "",
      dob_day: dob !== null && dob !== "" ? dob.split("-")[2] : "",
      dob_year: dob !== null && dob !== "" ? dob.split("-")[0] : "",
      testResult: "",
      photoUploaded: false,
    });
  };

  getProjectDetails = () => {
    let projectId = this.props.match.params.id;
    let docRef = firebase.firestore().collection("projects").doc(projectId);
    let today = moment().format("YYYY-MM-DD");

    docRef
      .get()
      .then((doc) => {
        const project = doc.exists ? doc.data() : null;
        const rapidTest = Boolean(project.testType === 'rapid' || project.testType === 'rapid-supervised');
        const pcrTest = Boolean(project.testType === 'pcr');
        if (((rapidTest && !project.projectClosed) || (pcrTest && project.date >= today))) {
          this.setState({
            project: project,
            isLoading: false,
          });
          analytics.logEvent("patform_load", { projectId: projectId });
        } else {
          this.setState({
            formDisplay: "expired",
            isLoading: false,
          });
          analytics.logEvent("patform_expired", { projectId: projectId });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getTimeRange = () => {
    const from = this.state.project.time.from.split(":");
    // const to = this.state.project.time.to.split(":");

    let times = [];
    var d = new Date();
    d.setHours(from[0]);
    d.setMinutes(from[1]);
    var keepGoing = true;

    // If already patients
    if (this.state.project.patients !== undefined) {
      const timeSelected = [];
      // Create an array with all times already selected
      this.state.project.patients.forEach((value, index) => {
        timeSelected.push(value.time);
      });

      let patientSlot =
        this.state.project.patientSlot !== undefined
          ? this.state.project.patientSlot
          : 4;

      while (keepGoing) {
        var m1 = ((((d.getMinutes() + 7.5) / 15) | 0) * 15) % 60;
        var h1 = (((d.getMinutes() / 105 + 0.5) | 0) + d.getHours()) % 24;
        d = new Date(d.getYear(), d.getMonth(), d.getDay(), h1, m1, 0, 0);

        let time = ("0" + h1).slice(-2) + ":" + ("0" + m1).slice(-2);

        if (time === this.state.project.time.to) {
          keepGoing = false;
        }

        d = new Date(d.getTime() + 15 * 60000);

        if (
          timeSelected.filter((v) => v === time).length < patientSlot &&
          keepGoing
        ) {
          times.push(
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          );
        }
      }
      return times;
    } else {
      while (keepGoing) {
        var m = ((((d.getMinutes() + 7.5) / 15) | 0) * 15) % 60;
        var h = (((d.getMinutes() / 105 + 0.5) | 0) + d.getHours()) % 24;
        d = new Date(d.getYear(), d.getMonth(), d.getDay(), h, m, 0, 0);

        let time = ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);

        if (time === this.state.project.time.to) {
          keepGoing = false;
        }

        d = new Date(d.getTime() + 15 * 60000);

        if (keepGoing) {
          times.push(
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          );
        }
      }
      return times;
    }
  };

  getDays = () => {
    let days = [];

    for (let i = 1; i <= 31; i++) {
      let day = i;

      if (day < 10) {
        day = "0" + day;
      }

      days.push(
        <MenuItem key={day} value={day}>
          {day}
        </MenuItem>
      );
    }

    return days;
  };

  getYears = () => {
    let years = [];
    let today = new Date().getFullYear();
    let maxYear = today - 101;

    for (let i = today; i >= maxYear; i--) {
      years.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return years;
  };

  handleSubmit = () => {
    this.setState({
      isSubmitting: true,
      dob: (this.state.dob_year && this.state.dob_month && this.state.dob_day)
        ? `${this.state.dob_year}-${this.state.dob_month}-${this.state.dob_day}`
        : "",
    });

    let first_name =
      this.state.first_name.charAt(0).toUpperCase() +
      this.state.first_name.slice(1);

    let last_name =
      this.state.last_name.charAt(0).toUpperCase() +
      this.state.last_name.slice(1);

    firebase
      .firestore()
      .collection("patients")
      .where("phone", "==", this.state.phone)
      .where("first_name", "==", first_name)
      .where("last_name", "==", last_name)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          // If already exists, add to project
          querySnapshot.forEach((doc) => {
            this.addPatToProject(doc.data().id);
          });
        } else {
          // Create a new patient
          this.createNewPatient();
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  createNewPatient = () => {
    let newPatientRef = firebase.firestore().collection("patients").doc();
    let newPatientData = {
      id: newPatientRef.id,
      first_name:
        this.state.first_name.charAt(0).toUpperCase() +
        this.state.first_name.slice(1),
      last_name: this.state.last_name,
      dob: this.state.dob,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      postal_code: this.state.postal_code,
      gender: this.state.gender,
    };

    // Create the new patient.
    newPatientRef.set(newPatientData).then(() => {
      // Set patient ont the project
      this.addPatToProject(newPatientRef.id);
    });
  };

  async addPatToProject(patId) {
    window.scrollTo(0, 0);
    this.setState({
      patId: patId,
    });

    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.projectId);

    // Get the patients on the project.
    let patientsArray = await projectRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data().patients;
      }
      return [];
    });

    // If patient already on the project, we update the time
    if (
      patientsArray !== undefined &&
      patientsArray.filter((pat) => pat.id === patId).length === 1
    ) {
      patientsArray.filter((pat) => {
        if (pat.id === patId) {
          pat.time =
            this.state.project.time.from !== "00:00"
              ? this.state.pat_time
              : "00:00";
          pat.result = this.state.project.testType === 'rapid' || this.state.project.testType === 'rapid-supervised' ? 'NEG' : '';
          pat.termsConditions = this.state.termsConditions;

          return pat;
        }
        return pat;
      });

      projectRef
        .update({
          patients: patientsArray,
        })
        .then(() => {
          // Add Message user we all good
          this.setState({
            formDisplay: "submited",
            isSubmitting: false,
          });
          // Send email notification
          this.sendEmail();
          // Add to analytics
          analytics.logEvent("pat_updated_from_form", {
            patId: patId,
          });
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      // If patient is not on the project we insert
    } else {
      const result = this.state.project.testType === 'rapid' || this.state.project.testType === 'rapid-supervised' ? 'NEG' : 'ND';
      projectRef
        .update({
          patients: firebase.firestore.FieldValue.arrayUnion({
            id: patId,
            time:
              this.state.project.time.from !== "00:00"
                ? this.state.pat_time
                : "00:00",
            result: result,
            termsConditions: this.state.termsConditions,
            bookedAt: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            ).toUTCString(),
          }),
          patientTotal:
            this.state.project.patientTotal !== undefined
              ? this.state.project.patientTotal + 1
              : 1,
        })
        .then(() => {
          // Add Message user we all good
          this.setState({
            formDisplay: "submited",
            isSubmitting: false,
          });
          // Send email notification
          this.sendEmail();
          // Add to analytics
          analytics.logEvent("pat_add_from_form", {
            projectId: this.state.projectId,
          });
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }

  sendEmail() {
    // Proejct data
    let location = this.state.project.location
      .replace(/\s/g, "%20")
      .replace("#", "%23");

    let rawDate = new Date(
      this.state.project.date.replace(/-/g, "/")
    ).toDateString();
    let date = rawDate.replace(/\s/g, "%20");
    // Patient data
    let first_name = this.state.first_name.replace(/\s/g, "%20");
    let last_name = this.state.last_name.replace(/\s/g, "%20");
    let email = this.state.email.replace(/\s/g, "%20");
    let dob = this.state.dob.replace(/\s/g, "%20");
    let phone = this.state.phone.replace("-", "%20");
    let address = this.state.address.replace(/\s/g, "%20").replace("#", "%23");
    let postal_code = this.state.postal_code.replace(/\s/g, "%20");
    let gender = this.state.gender;
    let projectId = this.state.project.id;

    let time =
      this.state.project.time.from !== "00:00"
        ? this.state.pat_time.replace(/\s/g, "%20")
        : "";

    let xmlHttp = new XMLHttpRequest();
    let emailUrl = "";
    let bookingSystemUrl = "us-central1-set-protect";

    emailUrl = `https://${bookingSystemUrl}.cloudfunctions.net/sendMail?location=${location}&project=${projectId}&date=${date}&time=${time}&dest=${email}&first_name=${first_name}&last_name=${last_name}&dob=${dob}&phone=${phone}&address=${address}&postal_code=${postal_code}&gender=${gender}`;
    //emailUrl = `http://localhost:5001/set-protect/us-central1/sendMail?location=${location}&project=${projectId}&date=${date}&time=${time}&dest=${email}&first_name=${first_name}&last_name=${last_name}&dob=${dob}&phone=${phone}&address=${address}&postal_code=${postal_code}&gender=${gender}`;

    // only send to cloud function if the email address is populated
    if(email.length > 0) {
      xmlHttp.open("GET", emailUrl, false);
      xmlHttp.send(null);
      return xmlHttp.responseText;
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseAlert = (event) => {
    this.setState({
      isAlertOpen: false,
    });
  };

  onActionDone = () => {
    this.setState({
      isTermsModalOpen: false,
    });
  };

  handleTermsModal = () => {
    this.setState({
      isTermsModalOpen: !this.state.isTermsModalOpen,
    });
  };

  async handleResult(result) {
    let projectRef = firebase
      .firestore()
      .collection("projects")
      .doc(this.state.projectId);

    // Get the patients on the project.
    let patientsArray = await projectRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data().patients;
      }
      return [];
    });

    patientsArray.filter((pat) => {
      if (pat.id === this.state.patId) {
        pat.result = result;
        return pat;
      }
      return pat;
    });
    
    projectRef
    .update({
      patients: patientsArray,
    }).then(() => {
      // Add Message user we all good
      window.scrollTo(0, 0);
      this.setState({
        formDisplay: "resultsRecorded",
        testResult: result,
      });
    }).catch((error) => {
      // Display error to console
      console.error("Error updating document: ", error);
    });

  }

  testSteps = [
    {
      key: 'step1',
      title: 'Step 1',
      label: 'Pour entire buffer solution ampule into empty vial.',
      note1: '',
      note2: '',
      imgPath: '/img/step1.png',
    },
    {
      key: 'step2',
      title: 'Step 2',
      label: 'Remove swab from pouch.',
      note1: '',
      note2: '',
      imgPath: '/img/step2.jpg',
    },
    {
      key: 'step3',
      title: 'Step 3',
      label: 'Slowly rotate the swab 7 times over the suface of the nostril.',
      note1: '',
      note2: '',
      imgPath: '/img/step3.jpg',
    },
    {
      key: 'step4',
      title: 'Step 4',
      label: 'Slowly remove the swab from the nostril while still rotating.',
      note1: 'Repeat steps 3-4 on other nostril.',
      note2: '',
      imgPath: '/img/step4.jpg',
    },
    {
      key: 'step5',
      title: 'Step 5',
      label: 'Place swab into prepared vial with buffer solution. Slightly swirl the vial and swab for 30 seconds.',
      note1: 'Note: DO NOT place the cap on the vial.',
      note2: 'Note: DO NOT remove swab.',
      imgPath: '/img/step5.png',
    },
    {
      key: 'step6',
      title: 'Step 6',
      label: 'Press tip against side of vial to squeeze liquid from swab for 10 seconds. Then place swab back into the buffer solution.',
      note1: 'Note: DO NOT remove swab.',
      note2: '',
      imgPath: '/img/step6.png',
    },
    {
      key: 'step7',
      title: 'Step 7',
      label: 'Place new test strip, arrow pointing down, into the vial with the swab.',
      note1: 'Note: Touch strip on coloured area only.',
      note2: '',
      imgPath: '/img/step7.png',
    },
    {
      key: 'step8',
      title: 'Step 8',
      label: 'Place cap securely onto the vial with the test strip and swab inside.',
      note1: 'Note: Cap will permanently lock into place.',
      note2: 'Note: DO NOT tip vial over as this will invalidate the test.',
      imgPath: '/img/step8.png',
    },
  ];

  async handlePatientUpdate() {
    let projectRef = firebase
    .firestore()
    .collection("projects")
    .doc(this.state.projectId);
    // Get the patients on the project.
    let patientsArray = await projectRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data().patients;
      }
      return [];
    });

    patientsArray.filter((pat) => {
      if (pat.id === this.state.patId) {
        pat.hasPhoto = true;
        return pat;
      }
      return pat;
    });
    
    projectRef.update({
      patients: patientsArray,
    }).then(() => {
      this.setState({
        formDisplay: "resultsRecorded",
        photoUploaded: true,
      });
      window.scrollTo(0, 0);
    }).catch((error) => {
      console.error("Error updating document: ", error);
    });
  }

  handleTakePhoto (dataUri) {
    const projectId = this.state.projectId;
    const patientId = this.state.patId;
    // Do stuff with the photo...
    var storageRef = firebase.storage().ref('test-results/'+projectId+'/'+patientId+'.jpg');
    // Create file metadata including the content type
    var metadata = {
        contentType: 'image/jpeg',
    };

    // Upload the file and metadata
    storageRef.putString(dataUri, 'data_url', metadata).then((upload) => {
      this.handlePatientUpdate();
      console.log('Uploaded complete'); 
    }).catch((error) => {
      console.log('Could not upload photo'); 
    });
  }

  render() {
    const { classes } = this.props;
    const {
      project,
      pat_time,
      first_name,
      last_name,
      dob_month,
      dob_year,
      dob_day,
      email,
      phone,
      address,
      postal_code,
      gender,
      formDisplay,
      isSubmitting,
      isLoading,
      isAlertOpen,
      isTermsModalOpen,
      photoUploaded,
    } = this.state;
    
    const ColorButton = withStyles((theme) => ({
      root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[700],
        },
      },
    }))(Button);

    return (
      <Container component="main" maxWidth="xs">
        {formDisplay === "form" ? (
          <div>
            {isLoading ? (
              <Box style={{ textAlign: "center", marginTop: "50%" }}>
                <CircularProgress
                  size={100}
                  thickness={1}
                  style={{ color: "#C71E39" }}
                />
              </Box>
            ) : (
              <ValidatorForm onSubmit={this.handleSubmit}>
                <Paper className={classes.paper}>
                  <Box>
                    <Grid item xs={12} className={classes.header}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <img
                          style={{ width: "120px" }}
                          src={"/set-protect-logo.jpeg"}
                          alt={"logo"}
                        />
                      </Box>
                      <Typography variant="h5">{project.name}</Typography>
                      {/* date  */}
                      <Typography
                        variant="body1"
                        style={{ display: "flex", marginTop: 10 }}
                      >
                        <Box component="span" mr={1}>
                          <EventAvailableIcon fontSize="small" />
                        </Box>
                        {
                        /* display project date when the project has time  */
                        project.testType === 'pcr' || (project.time.to !== "00:00" && project.time.from !== "00:00") ?
                        <Box component="span">
                          {moment(project.date).format("dddd, MMMM DD YYYY")}
                        </Box>
                        :
                        /* display todays date when the project doesn't have time  */ 
                        <Box component="span">
                          {moment().format("dddd, MMMM DD YYYY")}
                        </Box>
                        }
                      </Typography>
                      {/* location  */}
                      <Typography variant="body1" style={{ display: "flex" }}>
                        <Box component="span" mr={1}>
                          <LocationOnIcon fontSize="small" />
                        </Box>
                        <Box component="span">{project.location}</Box>
                      </Typography>
                      {/* Test Type */}
                      {project.testType === "rapid" ? (
                      <Typography
                        variant="body1"
                        style={{ display: "flex" }}
                      >
                        <Box component="span" mr={1}>
                          <TimerIcon
                            fontSize="small"
                          />
                        </Box>
                        <Box component="span">Rapid Test { project.testType === 'rapid-supervised' ? " - Supervised" : null }</Box>
                      </Typography>
                      ) : null }
                      {/* Details  */}
                      <Typography variant="body1" style={{ display: "flex" }}>
                        <Box component="span" mr={1}>
                          <DescriptionIcon fontSize="small" />
                        </Box>
                        <Box component="span">{project.description}</Box>
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      {project.time.from !== "00:00" ? (
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            className={(classes.formControl, classes.input)}
                            fullWidth
                          >
                            <InputLabel id="select-time">
                              Select a Time
                            </InputLabel>
                            <Select
                              labelId="select-time"
                              id="pat_time"
                              name="pat_time"
                              value={pat_time}
                              onChange={this.handleChange}
                              label="Select a Time"
                              fullWidth
                              required
                            >
                              {this.getTimeRange()}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : null}

                      <Grid item xs={12} sm={6}>
                        <TextValidator
                          name="first_name"
                          variant="outlined"
                          fullWidth
                          id="first_name"
                          label="Legal First Name"
                          value={first_name}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          spellCheck="true"
                          className={classes.input}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          id="last_name"
                          label="Legal Last Name"
                          name="last_name"
                          value={last_name}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          spellCheck="true"
                          className={classes.input}
                        />
                      </Grid>
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12}>
                        <FormLabel component="legend">Date of Birth</FormLabel>
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12} sm={4}>
                        <FormControl
                          variant="outlined"
                          className={(classes.formControl, classes.input)}
                          fullWidth
                        >
                          <InputLabel id="select-month">Month</InputLabel>
                          <Select
                            labelId="select-month"
                            id="dob_month"
                            name="dob_month"
                            value={dob_month}
                            onChange={this.handleChange}
                            label="Month"
                            fullWidth
                            required
                          >
                            <MenuItem value="01">Jan</MenuItem>
                            <MenuItem value="02">Feb</MenuItem>
                            <MenuItem value="03">Mar</MenuItem>
                            <MenuItem value="04">Apr</MenuItem>
                            <MenuItem value="05">May</MenuItem>
                            <MenuItem value="06">Jun</MenuItem>
                            <MenuItem value="07">Jul</MenuItem>
                            <MenuItem value="08">Aug</MenuItem>
                            <MenuItem value="09">Sep</MenuItem>
                            <MenuItem value="10">Oct</MenuItem>
                            <MenuItem value="11">Nov</MenuItem>
                            <MenuItem value="12">Dec</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12} sm={4}>
                        <FormControl
                          variant="outlined"
                          className={(classes.formControl, classes.input)}
                          fullWidth
                        >
                          <InputLabel id="select-day">Day</InputLabel>
                          <Select
                            labelId="select-day"
                            id="dob_day"
                            name="dob_day"
                            value={dob_day}
                            onChange={this.handleChange}
                            label="Day"
                            fullWidth
                            required
                          >
                            {this.getDays()}
                          </Select>
                        </FormControl>
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12} sm={4}>
                        <FormControl
                          variant="outlined"
                          className={(classes.formControl, classes.input)}
                          fullWidth
                        >
                          <InputLabel id="select-year">Year</InputLabel>
                          <Select
                            labelId="select-year"
                            id="dob_year"
                            name="dob_year"
                            value={dob_year}
                            onChange={this.handleChange}
                            label="Year"
                            fullWidth
                            required
                          >
                            {this.getYears()}
                          </Select>
                        </FormControl>
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          value={email}
                          onChange={this.handleChange}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "Email is not valid",
                          ]}
                          className={classes.input}
                        />
                      </Grid>
                      : null }
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          name="phone"
                          label="Phone Number"
                          type="number"
                          id="phone"
                          value={phone}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className={classes.input}
                        />
                      </Grid>
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          name="address"
                          label="Address"
                          id="address"
                          value={address}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          spellCheck="true"
                          className={classes.input}
                        />
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          name="postal_code"
                          label="Postal Code"
                          id="postal_code"
                          value={postal_code}
                          onChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          className={classes.input}
                        />
                      </Grid>
                      : null }
                      { project.testType !== 'rapid' && project.testType !== 'rapid-supervised' ? 
                      <Grid item xs={12}>
                        <FormLabel component="legend">Sex</FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="gender"
                          value={gender}
                          required
                          onChange={this.handleChange}
                        >
                          <FormControlLabel
                            value="female"
                            control={
                              <Radio
                                disableRipple
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            value="male"
                            control={
                              <Radio
                                disableRipple
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            value="other"
                            control={
                              <Radio
                                disableRipple
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked,
                                }}
                              />
                            }
                            label="Prefer not to say"
                          />
                        </RadioGroup>
                      </Grid>
                      : null }
                      { project.testType === 'rapid' || project.testType === 'rapid-supervised' ?
                      <Grid item xs={12}>
                          <Typography variant="body1">
                          By using this web application I agree to <a href="/terms-conditions" target={'blank'}>Set Protect Terms of Use</a> and <a href="/privacy-policy" target={'blank'}>Privacy Policy</a>. I also acknowledge that I have read the CovClear COVID-19 Rapid Antigen Tests <a href="/pdf/CovClearCanada_IFUQRG_Print_Rev0-1.pdf" target={'blank'}>quick reference guide</a>.
                          </Typography>
                        <FormControlLabel
                          value="agreed"
                          label="Agree"
                          style={{
                            marginTop: "20px",
                          }}
                          control={<Checkbox
                            name="termsConditions"
                            onChange={this.handleChange}
                            required
                            />}
                        />
                      </Grid>
                      : null }
                    </Grid>

                    <div className={classes.wrapper}>
                      <Button
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        margin="normal"
                        color="primary"
                        className={classes.submit}
                        disableElevation
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={25}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Box>
                </Paper>
              </ValidatorForm>
            )}
          </div>
        ) : null}
        {formDisplay === "submited" && project.testType === 'rapid' ? (
          <Paper
            className={classes.paper}
            style={{ minHeight: 500, marginTop: 50, display: "flex" }}>
            <Grid item xs={12} className={classes.header}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  style={{ width: "120px" }}
                  src={"/set-protect-logo.jpeg"}
                  alt={"logo"}
                />
              </Box>
            </Grid>
            <Box component="span" mb={1}>
              <Typography
                variant="body1"
                style={{ display: "flex", marginTop: 5 }}
              >
                Instructions for a Self-Administered Test
              </Typography>
            </Box>
            <Box component="span" mb={1}>
              <Typography
                variant="h4"
                style={{ display: "flex", marginTop: 5 }}
              >
              Set Protect 
              </Typography>
              <Typography
                variant="h4"
                style={{ display: "flex", marginTop: 5 }}
              >
                Step-By-Step Guide
              </Typography>
            </Box>
            {this.state.activeStep === 0 ?
            <Grid 
              container
              style={{
                backgroundColor: "#FFBD59"
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  style={{ display: "flex", marginTop: 5, justifyContent: "center" }}
                >
                  Prepare to Test
                </Typography>
                <ol>
                  <li>Before opening the package, find a flat surface</li>
                  <li>Use only the swab provided</li>
                  <li>Touch test strip on the coloured end only</li>
                </ol>
              </Grid>
            </Grid>
            : null }
            <Grid container style={{
              backgroundColor: "#460700"
            }}>
              <Grid item xs={12}>
              <Typography
                  variant="h3"
                  style={{
                    display: "flex",
                    marginTop: 5, 
                    color: "#FFFFFF",
                    justifyContent: "center" 
                  }}
                >
                  Test Procedure
                </Typography>
              </Grid>
            </Grid>
            { this.testSteps.map( step => {
              return(
                <Grid key={step.key} container spacing={3}>
                  <Grid item xs={6}>
                  <Typography
                      variant="h4"
                      style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                        {step.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                        {step.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      className={classes.img}
                      src={step.imgPath}
                      alt={step.label}
                      style={{
                        height: 150,
                        marginTop: 15,
                      }}
                    />
                  </Grid>
                  { step.note1.length > 0 ?
                  <Grid item xs={12} style={{
                    backgroundColor: "#460700",
                    margin: 12,
                  }}>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", color: "#FFFFFF" }}>
                        {step.note1}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ display: "flex", marginTop: 5, color: "#FFFFFF" }}>
                        {step.note2}
                    </Typography>
                  </Grid>
                  : null }
                </Grid>
              );
              })
            }
            <Grid container >
              <Grid item xs={12} style={{
                backgroundColor: "#FFBD59"
              }}>
                <Typography
                  variant="h3"
                  style={{ display: "flex", padding: 10, marginTop: 5, justifyContent: "center", textTransform: "uppercase" }}
                >
                  Read results at 20 minutes
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img
                  className={classes.img}
                  src="/img/results-key.png"
                  alt="Results key"
                  style={{
                    height: 300,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 10,
                }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    style={{ width: "100%" }}
                    onClick={ () => {this.handleResult('POS')}}
                  >Positive Result - Click Here
                  </Button>
                </Grid>
                <Grid item xs={12} style={{
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 10,
                }}>
                  <ColorButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="large"
                    style={{ width: "100%" }}
                    onClick={() => {this.handleResult('NEG')}}
                  >Negative Result - Click Here
                  </ColorButton>
                </Grid>
                <Grid item xs={12} style={{
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 10,
                }}>
                  <Button
                    variant="contained"
                    color="default"
                    disableElevation
                    size="large"
                    style={{ width: "100%" }}
                    onClick={() => {this.handleResult('INV')}}
                  >Invalid Result - Click Here
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Box>
              <Typography
                variant="body1"
                style={{ padding: 10, marginTop: 5, }}
              >
              If you have any questions or any doubt with the validity of your test please call:<br />Paula <a href="tel: +1-604-240-9943">604-240-9943</a>.
              </Typography>
            </Box>
          </Paper>

        ) : null }
        {formDisplay === "submited" && project.testType === 'rapid-supervised' && project.time.to === "00:00" && project.time.from === "00:00" ? (
          <Paper
            className={classes.paper}
            style={{ minHeight: 500, marginTop: 50, display: "flex" }}>
            <Grid item xs={12} className={classes.header}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  style={{ width: "120px" }}
                  src={"/set-protect-logo.jpeg"}
                  alt={"logo"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{
                backgroundColor: "#FFBD59"
              }}>
                <Typography
                  variant="h3"
                  style={{ display: "flex", padding: 10, marginTop: 5, justifyContent: "center", textTransform: "uppercase" }}
                >
                  Supervised Test
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ display: "flex", padding: 10, marginTop: 5, }}
                >
                  {this.state.first_name}, please follow the supervising nurses guidance to complete your rapid test.
                </Typography>
              </Grid>
          </Paper>
        ) : null }
        {formDisplay === "resultsRecorded" ? (
          <Paper
            className={classes.paper}
            style={{ minHeight: 500, marginTop: 50, display: "flex" }}
          >
            <Grid item xs={12} className={classes.header}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  style={{ width: "120px" }}
                  src={"/set-protect-logo.jpeg"}
                  alt={"logo"}
                />
                <Link
                  href="https://www.setprotect.ca"
                  color="primary"
                  style={{ margin: 12 }}
                >
                  Our Website
                </Link>
              </Box>
              <Typography
                  variant="body1"
                  style={{
                    marginTop: 12,
                  }}
                >
                  Your results have been sent
                </Typography>
            </Grid>
            { photoUploaded === false 
              && (
                this.state.projectId === 'l1KWXY42Hh2YqB2xoAgq' || 
                this.state.projectId === 'ZxHMfWjsalfmuxnp3jFu'
              )
            ?
            <Grid>
              <Camera
                onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } } 
                imageType = { IMAGE_TYPES.JPG }
                imageCompression = { 0.85 }
                idealFacingMode = { FACING_MODES.ENVIRONMENT }
                isImageMirror = { false }
              />
            </Grid>
            : null }
            { this.state.testResult === 'POS' ?
              <Grid container>
                <Grid item xs={12} style={{
                    backgroundColor: "#C71F39",
                    color: "#FFFFFF",
                  }}>
                    <Typography
                      variant="h4"
                      style={{ display: "flex", padding: 10, marginTop: 5, justifyContent: "center", textTransform: "uppercase" }}
                    >
                      Positive Result
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: 5}}>
                    <Typography
                      variant="body1"
                    >
                    A positive result means that proteins from the virus that causes COVID-19 have been detected in your sample and it is very likely that you have COVID-19. You should self-isolate and seek follow-up care with your healthcare provider as additional testing may be necessary. There is a very small chance that this test can give you a positive test result that is wrong (false positive).
                    </Typography>
                </Grid>
              </Grid>
            : null }
            { this.state.testResult === 'NEG' ?
              <Grid container>
                <Grid item xs={12} style={{
                    backgroundColor: "#4caf50",
                    color: "#FFFFFF",
                  }}>
                    <Typography
                      variant="h4"
                      style={{ display: "flex", padding: 10, marginTop: 5, justifyContent: "center", textTransform: "uppercase" }}
                    >
                      Negative Result
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: 5}}>
                    <Typography
                      variant="body1"
                    >
                    A negative test result means that proteins have not been detected in your sample. It is possible for this test to give a negative result that is incorrect (false negative) in some people with COVID-19. This means you could possibly still have COVID-19 even though the test is negative. If you test negative and continue to experience COVID-19 symptoms of fever, cough and/or shortness of breath, seek follow-up care.
                    </Typography>
                </Grid>
              </Grid>
            : null }
            { this.state.testResult === 'INV' ?
              <Grid container>
                <Grid item xs={12} style={{
                    backgroundColor: "#CCCCCC",
                    color: "#FFFFFF",
                  }}>
                    <Typography
                      variant="h4"
                      style={{ display: "flex", padding: 10, marginTop: 5, justifyContent: "center", textTransform: "uppercase" }}
                    >
                      Invalid Result
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: 5}}>
                    <Typography
                      variant="body1"
                    >
                    An invalid result means that you must take a new test, including a new sample. If you collect a new sample, complete a new test, and receive a second invalid result, contact the team at Set Protect. This means that there is a higher chance this test will give you a negative result when you have COVID-19.
                    </Typography>
                </Grid>
              </Grid>
            : null }
            <Grid item xs={12} style={{ margin: 5}}>
              <Typography 
                variant="body1"
                style={{
                  fontWeight: 700,
                  fontStyle: "italic",
                  marginTop: 5
                }}
              >
                Do not use this test as the only guide to manage your health. No matter what your test result is, please consult a healthcare professional if your symptoms persist or become more severe.
              </Typography>
            </Grid>
          </Paper>
        ) :null }
        {
          formDisplay === "submited" 
          && (project.testType === 'pcr' 
            || (
              project.testType === 'rapid-supervised' 
              && project.time.to !== "00:00"
              && project.time.from !== "00:00"
            ))
           ? (
          <Paper
            className={classes.paper}
            style={{ minHeight: 500, marginTop: 50, display: "flex" }}
          >
            <Box component="span">
              <CheckCircleOutlineRoundedIcon
                style={{ fontSize: 70, color: "green" }}
              />
            </Box>
            <Box component="span" mb={1}>
              <Typography variant="h4">Confirmed</Typography>
            </Box>
            <hr style={{ minWidth: "100%", marginTop: 25, marginBottom: 25 }} />
            <Grid item xs={12} className={classes.header}>
              <Typography variant="h6">{project.name}</Typography>
              {/* date  */}
              <Typography
                variant="body1"
                style={{ display: "flex", marginTop: 5 }}
              >
                <Box component="span" mr={1}>
                  <EventAvailableIcon fontSize="small" />
                </Box>
                <Box component="span">
                  {new Date(project.date.replace(/-/g, "/")).toDateString()}
                </Box>
              </Typography>
              {/* time  */}
              {project.time.from !== "00:00" ? (
                <Typography
                  variant="body1"
                  style={{ display: "flex", marginTop: 5 }}
                >
                  <Box component="span" mr={1}>
                    <QueryBuilderIcon fontSize="small" />
                  </Box>
                  <Box component="span">{pat_time}</Box>
                </Typography>
              ) : null}

              {/* location  */}
              <Typography variant="body1" style={{ display: "flex" }}>
                <Box component="span" mr={1}>
                  <LocationOnIcon fontSize="small" />
                </Box>
                <Box component="span">{project.location}</Box>
              </Typography>
              {/* Details  */}
              <Typography variant="body1" style={{ display: "flex" }}>
                <Box component="span" mr={1}>
                  <DescriptionIcon fontSize="small" />
                </Box>
                <Box component="span">{project.description}</Box>
              </Typography>
              {/* Email sent - only if email provided */}
              <hr
                style={{ minWidth: "100%", marginTop: 25, marginBottom: 25 }}
              />
              { project.testType === 'pcr' ?
              <Box
                component="span"
                style={{
                  display: "flex",
                  marginTop: 25,
                  textAlign: "center",
                }}>
                <Typography variant="h6">
                  A confirmation has been sent to your email address.
                </Typography>
              </Box>
              : null }
            </Grid>
          </Paper>
        ) : null}
        {formDisplay === "expired" ? (
          <Paper
            className={classes.paper}
            style={{ marginTop: "50%", display: "flex" }}
          >
            <Box component="span">
              <CancelRoundedIcon style={{ fontSize: 70, color: "red" }} />
            </Box>
            <Box component="span" mb={1} textAlign="center">
              <Typography variant="h5">
                This booking is not available. Please check the link again.
              </Typography>
            </Box>
          </Paper>
        ) : null}
        <Snackbar
          open={isAlertOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.handleCloseAlert}
        >
          <Alert severity="error" elevation={6} variant="filled">
            <span>You already booked a time</span>
            <IconButton
              size="small"
              aria-label="close"
              color="default"
              onClick={this.handleCloseAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Alert>
        </Snackbar>
        <TermsModal
          open={isTermsModalOpen}
          onClose={this.handleTermsModal}
          onActionDone={this.onActionDone}
        />
        <Grid item xs={12} style={{
            justifyContent: "center",
            textAlign: "center",
            margin: 12,
          }}>
          <img
            style={{ width: 150 }}
            src={"/img/poweredbyjengu_signature_logo300px.gif"}
            alt={"Powered by Jengu"}
          />
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PatForm);
